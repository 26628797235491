import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  Alert,
  Button,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  TableColumnType,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";
import { DynamicFiltersType, IUser, TableView, TableViewCategories } from "../../models/user";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { failedQueue, isRefreshing } from "../../services";
import { userSlice } from "../../store/reducers/user";
import { updateViewXHR } from "../../store/reducers/user/actionCreators";
import moment from "moment";
import { store } from "../../store";
import { FilterDropdownProps } from "antd/lib/table/interface";
import NumberFilter, { SearchValue } from "../TableCustomFilters/NumberFilter";
import SelectFilter from "../TableCustomFilters/SelectFilter";
import DateTimeFilter from "../TableCustomFilters/DateTimeFilter";
import { ModelPropertyCustomFieldsNames } from "../../models/settings";
import PreviewButtonGroup from "../PreviewButton/Group";
import ViewForm from "../../componentsform/ViewForm";
const api = require("../../services").default;

export type ColumnFilterType =
  | "NumberFilter"
  | "SelectFilter"
  | "DateTimeFilter"
  | "SearchFilter"
  | "BooleanFilter";

export type CustomTableColumn = {
  title: string;
  visible: 1 | 2 | 3;
  dataIndex: string;
  columnRenderComponent: string | null;
  filterComponent: ColumnFilterType | null;
  onCellFlag: boolean;
  objectKey: ModelPropertyCustomFieldsNames | null;
};

interface IProps {
  viewCategory: TableViewCategories;
  views: Array<TableView>;
  onChange: (value: string) => void;
  setColumns: (_c: CustomTableColumn[], activeIndex: number) => void;
  hideDivider?: boolean;
}

const ViewTabs: React.FC<IProps> = ({ viewCategory, views, onChange, setColumns, hideDivider }) => {
  const [activeKey, setActiveKey] = React.useState<string>(views[0]?.name);
  const [saveFilters, set_saveFilters] = React.useState<boolean>(false);
  const [delLoading, set_delLoading] = React.useState<number>(-1);
  const [view, set_view] = React.useState<TableView>();
  const dispatch = useAppDispatch();
  const _onChange = (activeKey: string) => {
    onChange(activeKey);
    setActiveKey(activeKey);
  };

  // if(!user.account.views || !user.account.views[viewCategory]) {
  //   return null;
  // }

  const onDelete = async (id: number) => {
    set_delLoading(id);
    const token = await localStorage.getItem("token");

    try {
      let response = await api.delete(`settings/view/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });
      dispatch(
        userSlice.actions.deleteViewById({
          id: id,
          typeCategory: viewCategory,
        }),
      );

      set_delLoading(-1);

      setActiveKey(views[0]?.name);
    } catch (error: any) {
      //lkl
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete(id));
        }
        return;
      }
      set_delLoading(-1);
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
  };

  return (
    <>
      <Modal
        title={view?.name ? t(TRANSLATION_KEY.editView) : t(TRANSLATION_KEY.addView)}
        destroyOnClose={true}
        onCancel={() => set_view(undefined)}
        visible={!!view}
        footer={null}
        closable={true}
      >
        {view && (
          <ViewForm
            viewCategory={viewCategory}
            view={view}
            onClose={(view) => {
              setActiveKey(view.name);
              setColumns(view.table_structure, 0);
              set_view(undefined);
            }}
          />
        )}
      </Modal>
      {!hideDivider && <Divider style={{ margin: "12px 0" }} />}
      <Tabs
        style={{ display: "none" }}
        onChange={_onChange}
        activeKey={activeKey}
        tabBarExtraContent={{
          left: (
            <Space style={{ marginRight: 21 }}>
              <Button
                type="link"
                onClick={() => {
                  set_view({
                    name: "",
                    id: 0,
                    filters: {},
                    table_structure: [],
                    view_type: "by_user",
                  });
                }}
                icon={
                  <PlusCircleOutlined
                    style={{
                      fontSize: 21,
                    }}
                  />
                }
              />
            </Space>
          ),
        }}
      >
        {views
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          ?.map((view, i) => (
            <Tabs.TabPane
              key={view.name}
              tab={
                <Space
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography.Text>{view.name}</Typography.Text>
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      icon={<EditOutlined />}
                      type="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        set_view(view);
                      }}
                    />
                    <Popconfirm
                      onVisibleChange={(visible) => {
                        let el = document.getElementsByTagName("body")[0];

                        if (visible) {
                          if (el) {
                            el.classList.add("ant-popover-open");
                          }
                        } else {
                          if (el) {
                            el.classList.remove("ant-popover-open");
                          }
                        }
                      }}
                      title={t(TRANSLATION_KEY.continueWithAction)}
                      onConfirm={(e) => {
                        onDelete(view.id);
                      }}
                    >
                      <Button
                        style={{ display: views.length === 1 ? "none" : "block" }}
                        onClick={(e) => e.stopPropagation()}
                        loading={delLoading === view.id}
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </Space>
                </Space>
              }
            />
          ))}
      </Tabs>
    </>
  );
};

export default ViewTabs;

export const customTableColumnRender = (
  dynamic: ColumnFilterType,
  columnKey: string,
  user: IUser,
  modelKey: ModelPropertyCustomFieldsNames | null,
) => {
  if (modelKey === null) {
    return (text: string, value: any) => {
      //do ovoga nikad neće doći kada se radi o dinamičnoj koloni ali je potrebno zbog tipova errora
      return "";
    };
  }
  if (dynamic === "DateTimeFilter") {
    // na osnovu filtera zanam koji komponent da vratim
    return (text: string, value: any) => {
      return value[modelKey][columnKey]?.value
        ? moment(value[modelKey][columnKey]?.value as string).format(
            user.account.date_format || "DD.MM.YYYY HH:mm",
          )
        : "-";
    };
  } else if (dynamic === "SelectFilter") {
    return (text: string, value: any) => {
      if (value[modelKey][columnKey]?.value === undefined) {
        return "-";
      }
      let _val = (value[modelKey][columnKey]?.value as string[]) || [];

      if (typeof _val !== "object" && !Array.isArray(_val)) {
        _val = [];
      }
      try {
        return _val ? (
          <PreviewButtonGroup title={""} limit={2}>
            {_val?.map((v, i) => (
              <React.Fragment key={i}>
                <Tag style={{ marginBottom: 6 }}>{v}</Tag>
                {(i + 1) % 5 === 0 && i !== 0 && <div style={{ width: "100%" }} />}
              </React.Fragment>
            ))}
          </PreviewButtonGroup>
        ) : (
          "-"
        );
      } catch (error) {
        console.log(error);
        return "-";
      }
    };
  } else if (dynamic === "BooleanFilter") {
    return (text: string, value: any) => {
      if (value[modelKey][columnKey]?.value === undefined) {
        return "-";
      }
      let unique = value[modelKey][columnKey]?.value as boolean;

      return unique ? (
        <Tag color="green">{t(TRANSLATION_KEY.yes)}</Tag>
      ) : (
        <Tag color="red">{t(TRANSLATION_KEY.no)}</Tag>
      );
    };
  } else {
    return (text: string, value: any) => {
      return (
        <Typography.Text
          style={{ maxWidth: 120 }}
          ellipsis={{
            tooltip: value[modelKey][columnKey]?.value,
          }}
        >
          {value[modelKey][columnKey]?.value || "-"}
        </Typography.Text>
      );
    };
  }
};

export const getFilterComponent = (
  //rzp
  value: CustomTableColumn,
  dropDownProps: FilterDropdownProps,
  viewIndex: number,
  tmpfilter: Record<string, any>,
  onChange: (value: any, filterType: ColumnFilterType, columnKey: string) => void,
  selectValues: string[],
) => {
  let date = new Date();

  if (value.filterComponent === "NumberFilter") {
    //number filter ne uzima u obzir da li je undefined i
    return (
      <NumberFilter
        column_key={value.dataIndex}
        onSave={(v, column_key) => {
          onChange(v, "NumberFilter", column_key);
        }}
        defaultSearchValue={{
          from: (tmpfilter[value.dataIndex + "__gte"] as number) || undefined,
          to: (tmpfilter[value.dataIndex + "__lte"] as number) || undefined,
        }}
        opened={date.getTime().toString()}
      />
    );
  } else if (value.filterComponent === "SelectFilter") {
    let defValue = tmpfilter[value.dataIndex + "__contains"] as string;
    let newDefValue = defValue ? defValue.split(",") : [];
    return (
      <Select
        onChange={(v) => {
          onChange(v, "SelectFilter", value.dataIndex);
        }}
        allowClear
        defaultValue={newDefValue}
        mode="multiple"
        style={{ minWidth: 200 }}
      >
        {selectValues.map((v, i) => (
          <Select.Option key={i} value={v}>
            {v}
          </Select.Option>
        ))}
      </Select>
    );
  } else if (value.filterComponent === "DateTimeFilter") {
    return (
      <DateTimeFilter
        column_key={value.dataIndex}
        defaultDateValue={{
          from: (tmpfilter[value.dataIndex + "__gte"] as string) || undefined,
          to: (tmpfilter[value.dataIndex + "__lte"] as string) || undefined,
        }}
        onChange={(v, column_key) => {
          onChange(v, "DateTimeFilter", column_key);
        }}
      />
    );
  } else if (value.filterComponent === "BooleanFilter") {
    return (
      <Select
        onChange={(v) => {
          onChange(v, "BooleanFilter", value.dataIndex);
        }}
        mode={undefined}
        defaultValue={tmpfilter[value.dataIndex] || undefined}
        style={{ minWidth: 200 }}
      >
        {[
          {
            name: t(TRANSLATION_KEY.all),
            id: "true,false",
          },
          {
            name: t(TRANSLATION_KEY.yes),
            id: "true",
          },
          {
            name: t(TRANSLATION_KEY.no),
            id: "false",
          },
        ].map((v, i) => (
          <Select.Option key={i} value={v.id}>
            {v.name}
          </Select.Option>
        ))}
      </Select>
    );
  } else if (value.filterComponent === "SearchFilter") {
    return (
      <Input.Search
        defaultValue={tmpfilter[value.dataIndex + "__icontains"] as string}
        onSearch={(val) => {
          onChange(val, "SearchFilter", value.dataIndex);
        }}
      />
    );
  }

  return <Alert message="filters" type="error" />;
};

export const saveTableDefinition = (
  tableDefinition: Array<
    TableView & {
      updated: number;
      active: boolean;
    }
  >,
  definitionFromBackend: TableView | undefined, // potreban orginal tablestructure sa backenda jer u tableDefinition su promjene gdje je title preveden
  viewToUpdate: TableViewCategories,
  hideSuccesAlert?: boolean,
) => {
  if (definitionFromBackend === undefined) {
    message.error(t(TRANSLATION_KEY.errorOnSaveData));
    return;
  }
  let tmpView = tableDefinition.find((x) => x.active);
  if (!tmpView) {
    message.error(t(TRANSLATION_KEY.errorOnGetData));
    return;
  }
  let table_structure_with_titles_with_trans_key = tmpView.table_structure.map((x) => {
    return {
      ...x,
      title:
        definitionFromBackend.table_structure.find((c) => c.dataIndex === x.dataIndex)?.title || "", // without translate
    };
  });
  let body: TableView = {
    table_structure: table_structure_with_titles_with_trans_key,
    name: tmpView.name,
    id: tmpView.id,
    filters: tmpView.filters,
    view_type: tmpView.view_type,
  };
  updateViewXHR(
    {
      errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
      id: tmpView.id,
      body,
      viewToUpdate,
      successCallback: (res) => {
        if (hideSuccesAlert) return;
        message.success(t(TRANSLATION_KEY.successfulChanged));
      },
    },
    store.dispatch,
  );
};

export function filterColumns<T>(arr: Array<Partial<CustomTableColumn> & TableColumnType<T>>) {
  let filtred = arr.filter((x) => x.visible === 1 || x.visible === 3);

  // items with actions shoud be always on end of columns of table
  let actions = arr.filter((x) => x.dataIndex === "actions");
  if (actions.length > 0) {
    filtred = filtred.filter((x) => x.dataIndex !== "actions");
    filtred = [...filtred, ...actions];
  }
  return filtred;
}

export const BooleanFilterConverter = (value: string, column_key) => {
  let ditc: Record<string, string> = {};

  ditc[column_key] = value;
  return ditc;
};

export const SearchConverter = (value: string, column_key: string) => {
  let ditc: Record<string, string> = {};
  ditc[column_key + "__icontains"] = value;
  return ditc;
};

export const SelectFilterConverter = (value: string[], column_key: string) => {
  let ditc: Record<string, string> = {};
  ditc[column_key + "__contains"] = value.join(",");
  return ditc;
};

export const DatetimeAndNumberFilterConverter = (
  from: string | undefined,
  to: string | undefined,
  columnKey: string,
) => {
  let ditc: Record<string, string> = {};
  if (from) {
    ditc[columnKey + "__gte"] = from;
  }
  if (to) {
    ditc[columnKey + "__lte"] = to;
  }
  return ditc;
};

const clearFiltersKeys = (columnName: string) => {
  let columnsSplited = columnName.split("__");
  if (columnsSplited.length === 1) {
    return columnName;
  }
  alert("clearFiltersKeys error");
};
