import { Spin, message, Row, Col, Typography } from "antd";
import { t } from "i18next";
import React, { useMemo, useState } from "react";
import { DONUT_CHART_CONFIG, TRANSLATION_KEY } from "../../../../helpers/consts";
import { useAppDispatch } from "../../../../hooks";
import { IApiResponse } from "../../../../models";
import api, { failedQueue, isRefreshing } from "../../../../services";
import { Column, Pie, PieConfig, Line, LineConfig } from "@ant-design/plots";
import Filters, { IFilters } from "./components/Filters";
import { parseCosts, renderStatistic } from "../../../../helpers/functions";
import { Datum } from "@ant-design/charts";
import Loader from "../../components/Loader";
import { useWindowSize } from "react-use";

interface IProps {}

export interface IItemTranslatetProps {
  trans_key: null | string;
  name: string | null;
}

export interface CountPerCategory {
  count: number;
  name: IItemTranslatetProps[];
  percentage: number;
}

export type CountPerType = {
  count: number;
  name: IItemTranslatetProps;
  percentage: number;
} & { color: string };

interface IReport {
  total_count: number;
  count_per_category: CountPerCategory[];
  count_per_type: CountPerType[];
}

interface IWeekData {
  week: string;
  types: {
    count: number;
    name: IItemTranslatetProps;
    color: string;
  }[];
}

type IGetMaintenancesCountByCount = Omit<IFilters, "calendarType" | "start" | "end">;

const Reportonemaintenance: React.FC<IProps> = () => {
  // Hooks
  const { width } = useWindowSize();

  // Variables
  const [loading, set_loading] = useState(false);
  const dispatch = useAppDispatch();
  const [maintenancesCountByCount, setMaintenancesCountByCount] = useState<IWeekData[]>([]);
  const [reportData, set_reportData] = useState<IReport>({
    total_count: 0,
    count_per_category: [],
    count_per_type: [],
  });

  // Methods
  async function getMaintenancesCountByCount(filters: IGetMaintenancesCountByCount) {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let res = await api.get<IApiResponse<IWeekData[]>>("/maintenance/analytics/graph_by_type", {
        headers: { Authorization: "Bearer " + token },
        params: {
          assets: filters.assets.join("|"),
          asset_categories: filters.asset_categories.join("|"),
          executor_supplier: filters.executor_supplier?.join("|"),
          executor_account: filters.executor_account?.join("|"),
          clients: filters.clients?.join("|"),
        },
      });
      if (res.data.results) {
        setMaintenancesCountByCount(res.data.results);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => getMaintenancesCountByCount(filters));
        }
        return;
      }
      console.log("error :>> ", error);

      message.error(t(TRANSLATION_KEY.errorOnGetData));
    }
    set_loading(false);
  }

  const getMaintenanceCount = async (filters: IFilters) => {
    //TUUU JE ERRORRRR
    set_loading(true);
    console.log(filters, "LLLLLL");
    let token = await localStorage.getItem("token");

    try {
      let res = await api.get<IApiResponse<IReport>>("/maintenance/analytics/maintenance_count", {
        headers: { Authorization: "Bearer " + token },
        params: {
          assets: filters.assets.join("|"),
          asset_categories: filters.asset_categories.join("|"),
          end: filters.end,
          start: filters.start,
          executor_account: filters.executor_account?.join("|"),
          executor_supplier: filters.executor_supplier?.join("|"),
          clients: filters.clients?.join("|"),
        },
      });
      console.log(res.data.results, "res.data.results");
      if (res.data.results) {
        set_reportData(res.data.results);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => getMaintenanceCount(filters));
        }
        return;
      }
      console.log("error :>rrr> ", error);

      message.error(t(TRANSLATION_KEY.errorOnGetData));
    }
    set_loading(false);
  };

  const donutConfig: PieConfig = {
    ...DONUT_CHART_CONFIG,
    radius: width < 1400 ? 0.7 : 0.85,
    angleField: "count",
    colorField: "name",
    meta: {
      value: {
        formatter: (v) => `${v}`,
      },
    },
    color: ({ name }) => {
      let color = "";
      color =
        reportData.count_per_type.find((x) => {
          console.log(x, name, "x", "name");
          return (
            t(x.name?.trans_key || x.name?.name || TRANSLATION_KEY.other) === name.split(" ")[0]
          );
        })?.color || "";
      return color;
    },
    data: reportData.count_per_type.map((x) => ({
      ...x,
      name: `${t(x.name?.trans_key || x.name?.name || TRANSLATION_KEY.other)} ${x.percentage}%`,
    })),
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.name : t(TRANSLATION_KEY.total);
          return renderStatistic(d, text, {
            fontSize: 22,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "32px",
        },
        customHtml: (container, view, datum, data: any) => {
          const { width } = container.getBoundingClientRect();
          const text = datum ? `${datum.count}` : `${data.reduce((r, d) => r + d.count, 0)}`;
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
    },
  };

  let columnChart = useMemo(
    () => (
      <>
        {/* Title */}
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.maintenancesByCategory)}
        </Typography.Title>
        {/* Divider */}
        <div style={{ marginTop: 12, marginBottom: 12 }} />
        {/* Chart */}
        <div style={{ maxHeight: 572 }}>
          <Column
            maxColumnWidth={60}
            tooltip={{
              formatter: (datum: Datum) => {
                return {
                  name: t(TRANSLATION_KEY.count),
                  value: datum.count,
                };
              },
            }}
            label={{
              content: (originData) => {
                return parseCosts(originData.percentage) + "%";
              },
            }}
            data={reportData.count_per_category.map((x) => ({
              ...x,
              name: !Array.isArray(x.name)
                ? "erroVerzija"
                : x.name?.map((c) => t(c.trans_key || c.name || "")).join(" ") ||
                  t(TRANSLATION_KEY.other),
            }))}
            xField="name"
            yField="count"
          />
        </div>
      </>
    ),
    [reportData],
  );

  let picChart = useMemo(
    () => (
      <>
        {/* Title */}
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.maintenanceByType)}
        </Typography.Title>
        {/* Divider */}
        <div style={{ marginTop: 12, marginBottom: 12 }} />
        {/* Chart */}
        <div style={{ maxHeight: 572 }}>
          <Pie {...donutConfig} />
        </div>
      </>
    ),
    [reportData],
  );

  const lineData = useMemo(
    () =>
      maintenancesCountByCount.flatMap((weekData) => {
        console.log(weekData, "weekData");
        return weekData.types.map((typeData) => ({
          week: weekData.week,
          name: typeData.name,
          count: typeData.count,
          color: typeData.color,
        }));
      }),
    [maintenancesCountByCount],
  );

  const lineConfig: LineConfig = {
    data: lineData.map((x) => ({
      ...x,
      name: `${t(x.name?.name || x.name?.trans_key || TRANSLATION_KEY.other)}`,
    })),
    xField: "week",
    yField: "count",
    seriesField: "name",
    color: (value: any) => {
      let color: string = "";
      color =
        lineData.find(
          (item) =>
            t(item.name?.name || item.name?.trans_key || TRANSLATION_KEY.other) === t(value.name),
        )?.color || "";
      return color;
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      label: {
        formatter: (v: string) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    lineStyle: {
      lineWidth: 5,
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 2000,
      },
      update: {
        animation: "path-in",
        duration: 750,
      },
    },
  };

  let lineChart = useMemo(
    () => (
      <>
        {/* Title */}
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.maintenanceNumberGraph)}
        </Typography.Title>
        {/* Divider */}
        <div style={{ marginTop: 12, marginBottom: 12 }} />
        {/* Chart */}
        <div style={{ maxHeight: 572 }}>
          <Line {...lineConfig} />
        </div>
      </>
    ),
    [maintenancesCountByCount],
  );

  return (
    <div>
      {/* Spinner */}
      <Loader isLoading={loading} />

      {/* Filters */}
      <Filters onChange={(f) => (getMaintenanceCount(f), getMaintenancesCountByCount(f))} />
      <div style={{ marginTop: 24 }}>
        <Row gutter={[24, 24]}>
          <Col xl={12} xxl={12} md={12} sm={24} xs={24} lg={12}>
            <div className="white-container" style={{ height: "100%", maxHeight: 480 }}>
              {columnChart}
            </div>
          </Col>
          <Col xl={12} xxl={12} md={12} sm={24} xs={24} lg={12}>
            <div className="white-container" style={{ height: "100%", maxHeight: 480 }}>
              {picChart}
            </div>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
          <Col span={24}>
            <div className="white-container" style={{ height: "100%", maxHeight: 480 }}>
              {lineChart}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Reportonemaintenance;
