import {
  message,
  Spin,
  Form,
  Input,
  TreeSelect,
  Select,
  Radio,
  DatePicker,
  InputNumber,
  Button,
  Divider,
  Typography,
  Upload,
  Modal,
  Space,
  Row,
  Col,
} from "antd";
import { t } from "i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useNavigate } from "react-router-dom";
import SelectTagAdd, { ButtonAddSelectTag, Tag } from "../components/SelectTagAdd";
import { IMAGE_TYPES, MATERIAL_EXTENSIONS, STATUSES, TRANSLATION_KEY } from "../helpers/consts";
import {
  compressImage,
  createTreeSelect,
  hasPermission,
  setItemsWithoutParentToHighestNode,
} from "../helpers/functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { ButtonSaveType, EXECUTOR_TYPES, IApiResponse, ILocation, SelectTreeItem } from "../models";
import { getAssetXHR, getSubAssetsXHR } from "../store/reducers/asstes/actionCreators";
import {
  createFullOrderXHR,
  getPotentialExecutorsXHR,
} from "../store/reducers/maintenance/actionCreator";
import { settingsSlice } from "../store/reducers/settings";
import { getChecklistsXHR } from "../store/reducers/settings/actionCreator";
import moment, { Moment } from "moment";
import { CustomFieldValues, ICustomValue } from "../models/settings";
import { CreateFullOrderRedirect, MaintenanceStatusTypes } from "../models/maintenances";
import FilesComponent from "../components/Files";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { IFile } from "../models/user";
import { ISubAsset } from "../models/asset";
import SubAssetForm from "./SubAssetForm";
import { maintenanceSlice } from "../store/reducers/maintenance";
import AssetForm from "./AssetForm";
import RequirePermission from "../components/RequirePermission";
import { getClientAssetXHR } from "../store/reducers/clients/actionCreators";
import ClientsSelect from "./ClientsSelect";
import GenerateForCustomFieldsV2, { customValuesCollector } from "./GenerateForCustomFieldsV2";

interface ISupplierExecutor {
  supplier: number;
  send_email: string | null;
  note: string;
}

export interface IFormExecutors {
  employees: null | number[];
  supplier: null | ISupplierExecutor;
  partner: null | number;
}

export interface IMaintenanceForm {
  client: number | null;
  description: string;
  asset: number | null;
  maintenance_type: number | null;
  maintenance_categories: number[];
  location: number | null;
  planned_start: Moment | null;
  time_needed: string | null;
  deadline: Moment | null;
  executors: string | string[] | null;
  executor_type: EXECUTOR_TYPES;
  custom_fields: ICustomValue[];
  checklist: number | null;
  status: MaintenanceStatusTypes | null;
  subassets: number[];
  work_request: number | null;
  orderconfirm_custom_fields_v2: CustomFieldValues;
  workorder_custom_fields_v2: CustomFieldValues;
}

interface IProps {
  onClose: () => void;
  maintenance?: IMaintenanceForm;
  redirectTo?: CreateFullOrderRedirect;
  hh?: number;
  mm?: number;
  asset?: number;
  description?: string;
  workRequestId?: number;
  updateWorkRequests?: () => void;
}

const { OptGroup, Option } = Select;

const STATUSES_TO_IGNORE: Partial<MaintenanceStatusTypes>[] = ["assigned", "cancelled"];
const MaintenanceForm: React.FC<IProps> = ({
  onClose,
  maintenance,
  redirectTo,
  hh,
  mm,
  asset, // ukoliko se kreira iz zahtjeva za radom
  description,
  workRequestId,
  updateWorkRequests,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [addLocationVisible, set_addLocationVisible] = useState<boolean>(false);
  const [files, set_files] = useState<RcFile[]>([]);
  const [previewFiles, set_previewFiles] = useState<IFile[]>([]);
  const { locations, getLocationsStatus, checklists, companyCustomFieldsV2 } = useAppSelector(
    (state) => state.settingsReducer,
  );
  const { getPartnersStatus } = useAppSelector((state) => state.partnerReducer);
  const { getSuppliersStatus } = useAppSelector((state) => state.supplierReducer);
  const { assetList, getAssetStatus, subAssets, getSubAssetsStatus } = useAppSelector(
    (state) => state.assetReducer,
  );

  const { user } = useAppSelector((state) => state.userReducer);
  const { getClientsStatus } = useAppSelector((state) => state.clientsReducer);

  const [addSubAssetVisible, set_addSubAssetVisible] = useState<ISubAsset>();
  const [form] = Form.useForm();
  const [emailForm] = Form.useForm();
  const [selectedExecutor, set_selectedExecutor] = useState<string>(); // kada se odabere executor ukoliko je drugog tipa mora se resetirat input i postavit zadnji odabrani
  const [executorType, set_executorType] = useState<EXECUTOR_TYPES>("employee");
  const [assetSelected, set_assetSelected] = useState<number>(0);
  const [supplierEmailVisible, set_supplierEmailVisible] = useState<boolean>(false);
  const [visibleAditionalFields, set_visibleAditionalFields] = useState<boolean>(false);
  const [filtredAsset, set_filtredAsset] = useState<SelectTreeItem[]>([]);
  const [assetModalVisible, set_assetModalVisible] = useState<boolean>(false);
  const [selectedmaintenanceType, set_selectedMaintenanceType] = useState<number | undefined>(
    undefined,
  );
  const [loadingSave, set_loadingSave] = useState<ButtonSaveType>();
  const inputDescriptionRef = useRef<Input>(null);

  const clientsSelectRef = useRef<any>(null);

  const {
    maintenancesTypes,
    maintenanceCategories,
    getMaintenancesCategoriesStatus,
    getMaintenancesTypesStatus,
    getPotentialExecutorsStatus,
    potentialExecutors,
    createFullOrderStatus,
    calendarMaintenances,
    liveMaintenances,
  } = useAppSelector((state) => state.maintenanceReducer);

  useEffect(() => {
    if (asset) {
      setClientIfHasModule(asset);
    }
  }, [asset]);
  useEffect(() => {
    if (asset) {
      set_assetSelected(asset);
      getSubAssets(asset);
    }

    if (!selectedExecutor) {
      return;
    }
    if (executorType === "employee") {
      form.setFieldsValue({ executors: [selectedExecutor] });
    } else {
      form.setFieldsValue({ executors: selectedExecutor });
    }
  }, [executorType]);
  const setPotentialExecutors = (asset_id?: number) => {
    form.setFieldsValue({ executor_type: "employee" });
    getPotentialExecutorsXHR(
      {
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        queryParams: {
          asset: asset_id,
        },
      },
      dispatch,
    );
  };

  useEffect(() => {
    set_filtredAsset(
      createTreeSelect(
        assetList.map((x) => ({
          title: x.path,
          value: x.id.toString(),
          parent_id: x.parent_id?.toString() || null,
          disabled: !x.can_report_order,
        })),
        null,
      ),
    );
  }, [assetList]);

  useEffect(() => {
    if (maintenance) {
      form.setFieldsValue({ planned_start: maintenance.planned_start });
    }
  }, [maintenance]);

  useEffect(() => {
    if (hh || mm) {
      form.setFieldsValue({ hh });
      form.setFieldsValue({ mm });
    }
  }, [hh, mm]);

  useEffect(() => {
    setPotentialExecutors();

    getAssetXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback: (res) => {},
      },

      dispatch,
    );
    getChecklistsXHR({}, dispatch);
  }, []);

  const onFinish = (
    values: IMaintenanceForm & { [key: string]: ICustomValue[] } & {
      hh: string | null;
      mm: string | null;
    },
  ) => {
    let formData = new FormData();
    let custom_fields: ICustomValue[] = [];

    let time_needed = `${values.hh || "0"}:${values.mm || "0"}:00`;

    let formatedData: Omit<IMaintenanceForm, "executors" | "deadline" | "planned_start"> & {
      executors: IFormExecutors | null;
      deadline: string | null;
      planned_start: string | null;
    } = {
      // ako neki property je undefined json stringfy ga ne ubaci u json string stoga moramo provjeriti vrijednosti i postaviti im prazne vrijednosti
      ...values,
      asset: values.asset || null,
      deadline: values.deadline ? values.deadline.toISOString(true) : null,
      time_needed: !values.mm && !values.hh ? null : time_needed,
      maintenance_categories: values.maintenance_categories || [],
      maintenance_type: values.maintenance_type || null,
      planned_start: values.planned_start ? values.planned_start.toISOString(true) : null,
      workorder_custom_fields_v2: customValuesCollector(values, companyCustomFieldsV2.workorder),
      status: values.executors ? "assigned" : values.status === "assigned" ? "open" : values.status,
      subassets: values.subassets || [],
      custom_fields: [], // ne mozemo ukloniti zbog starijih verzija fronta i mobilne koje mogu poslati ovaj pdoatak
      work_request: workRequestId || null,
      executors:
        createExecutorsObject(
          executorType,
          values.executors || "",
          emailForm.getFieldValue("sendEmail"),
          emailForm.getFieldValue("emailNote"),
        ) || null,
      location:
        values.location ||
        assetList.find((x) => x.id === +form.getFieldValue("asset"))?.location?.id ||
        null,
    };

    formData.append("data", JSON.stringify(formatedData));

    // Appending materials
    // Image compression function
    async function imageCompress(file: RcFile, callback: () => void) {
      let image: any = file;
      if (IMAGE_TYPES.includes(file.type)) {
        image = await compressImage(file);
      }
      formData.append("files", image);
      // Resolving promise
      callback();
    }

    // Appending and compressing files
    let requests = files.reduce((promiseChain, item) => {
      return promiseChain.then(
        () =>
          new Promise((resolve) => {
            imageCompress(item, resolve);
          }),
      );
    }, Promise.resolve());

    requests.then(() =>
      createFullOrderXHR(
        {
          body: formData,
          successCallback: (data) => {
            if (data.results) {
              if (redirectTo === "profile") {
                navigate(`${data.results.id}`);
              }
              let tmp = [...calendarMaintenances];
              tmp.push(data.results);
              dispatch(
                maintenanceSlice.actions.getCalendarMaintenancesSuccess({
                  results: tmp,
                  message: "",
                }),
              );

              if (updateWorkRequests) {
                updateWorkRequests();
              }

              if (loadingSave === "save") {
                onClose && onClose();
              } else {
                form.resetFields();
                set_previewFiles([]);
                set_files([]);
                let drawer = document.querySelector(".ant-drawer-body");
                if (drawer) {
                  drawer.scrollTop = 0;
                }
                inputDescriptionRef.current?.focus();
                set_loadingSave(undefined);
              }
            }
          },
          errorCallback: (err) => {
            if (err.response.data?.message?.custom_field) {
              message.error(
                t(err.response.data.message.message || "").replace(
                  "$_dynamic_column",
                  err.response.data.message.custom_field,
                ),
              );
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
        },
        dispatch,
      ),
    );
  };

  const memedAddingAsset = useMemo(
    () => (
      <Modal
        visible={assetModalVisible}
        onCancel={() => {
          set_assetModalVisible(false);
        }}
        footer={null}
        title={t(TRANSLATION_KEY.addAsset)}
        centered
        width="600px"
        closable
        destroyOnClose
      >
        <AssetForm
          hideSaveAndAdd={true}
          addingFromOrder={true}
          asset={undefined}
          close={(id?: number, autopilot?: boolean) => {
            set_assetSelected(id || -1);
            form.setFieldsValue({ asset: id?.toString() });
            if (autopilot) {
              form.setFieldsValue({
                status: "requested",
              });
            }
            set_assetModalVisible(false);
          }}
        />
      </Modal>
    ),
    [assetModalVisible],
  );

  // Materials
  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    // Setting preview files
    const tmpfiles: IFile[] = [];
    files.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);

    return false;
  };

  const getSubAssets = (id: number) => {
    getSubAssetsXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        queryParams: {
          asset: id,
        },
      },
      dispatch,
    );
    let asset = assetList.find((x) => x.id === +id);
    form.setFieldsValue({
      location: asset?.location?.id || null,
    });
  };

  const onRemove = (id: any): void => {
    // Find index
    let index = files.findIndex((x) => x.lastModified === id);
    // Remove from files
    let files_tmp = [...files];
    files_tmp.splice(index, 1);
    set_files(files_tmp);
    // Remove from preview files
    let pfiles_tmp = [...files];
    pfiles_tmp.splice(index, 1);
    const tmpfiles: IFile[] = [];
    pfiles_tmp.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);
  };

  //ukoliko imovina ima lokaciju sakrij select lokacija za nalog
  let showLocationInput = !assetList.find((x) => x.id === +form.getFieldValue("asset"))?.location;
  let _hasClients = user?.account?.company?.modules?.filter((x) => x.name === "clients").length > 0;

  const setClientIfHasModule = (asset_id: number) => {
    if (!_hasClients) {
      return;
    }
    let assTmp = assetList.find((x) => x.id === asset_id);
    let client_id = assTmp?.client_owner?.id || assTmp?.rented_to_client?.id || null;
    if (!client_id) {
      return;
    }
    if (!assTmp?.client_owner?.is_active && !assTmp?.rented_to_client?.is_active) {
      return;
    }
    clientsSelectRef.current?.callMissingClient(client_id);
    form.setFieldsValue({ client: client_id });
  };

  return (
    <div>
      <Modal
        title={t(TRANSLATION_KEY.emailForSendOrderToSupplier)}
        footer={null}
        visible={supplierEmailVisible}
        closable
        onCancel={() => {
          unstable_batchedUpdates(() => {
            set_supplierEmailVisible(false);
          }, []);
        }}
      >
        <Form
          form={emailForm}
          layout="vertical"
          onFinish={() => {
            set_supplierEmailVisible(false);
          }}
        >
          <Form.Item name="sendEmail" label={t(TRANSLATION_KEY.email)}>
            <Input />
          </Form.Item>
          <Form.Item name="emailNote" label={t(TRANSLATION_KEY.note)}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              {t(TRANSLATION_KEY.save)}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Spin
        spinning={
          getLocationsStatus === "loading" ||
          getMaintenancesCategoriesStatus === "loading" ||
          getMaintenancesTypesStatus === "loading" ||
          getAssetStatus === "loading"
        }
      >
        <Form
          onFinish={onFinish}
          layout="vertical"
          form={form}
          initialValues={{
            ...maintenance,
            status: "open",
            asset: asset || undefined,
            description: description || undefined,
          }}
        >
          {_hasClients ? (
            <ClientsSelect
              excludeWithoutClient={true}
              includeArchived={false}
              includeInactive={false}
              ref={clientsSelectRef}
              style={undefined}
              mode={undefined}
              value={form.getFieldValue("client")}
              label={t(TRANSLATION_KEY.client)}
              name={"client"}
              onChange={(a) => {
                form.setFieldsValue({ asset: null });
                if (!a) {
                  set_filtredAsset(
                    createTreeSelect(
                      assetList.map((x) => ({
                        title: x.path,
                        value: x.id.toString(),
                        parent_id: x.parent_id?.toString() || null,
                        disabled: !x.can_report_order,
                      })),
                      null,
                    ),
                  );
                  form.setFieldsValue({ asset: null });
                } else if (!Array.isArray(a)) {
                  getClientAssetXHR(
                    {
                      id: a,
                      queryParams: {
                        client_asset_type: "rent|ownership",
                        client: a,
                      },
                      errorCallback: () => {
                        message.error(t(TRANSLATION_KEY.errorOnGetData));
                      },
                      successCallback: (res) => {
                        if (!res.results) {
                          return;
                        }
                        set_filtredAsset(
                          createTreeSelect(
                            setItemsWithoutParentToHighestNode(
                              res.results as { parent_id: number | null; id: number }[],
                            ).map((x) => ({
                              title: x.path,
                              value: x.id.toString(),
                              parent_id: x.parent_id?.toString() || null,
                              disabled: false,
                            })),
                            null,
                          ),
                        );
                        console.log(res.results);
                      },
                    },

                    dispatch,
                  );
                }
              }}
              form={undefined}
            />
          ) : null}
          <Space align="center">
            <Form.Item
              rules={[{ required: false, message: t(TRANSLATION_KEY.filedRequired) }]}
              name={"asset"}
              label={t(TRANSLATION_KEY.asset)}
            >
              <TreeSelect
                style={{ width: 450 }}
                value={form.getFieldValue("asset")}
                treeNodeFilterProp="title"
                showSearch
                allowClear
                filterTreeNode={(search, item: any) => {
                  return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                }}
                onChange={(id: string) => {
                  if (id) {
                    getSubAssets(+id);
                    set_assetSelected(+id);
                    setPotentialExecutors(+form.getFieldValue("asset"));
                    setClientIfHasModule(+id);
                    let asset = assetList.find((x) => x.id === +id);
                    if (asset?.autopilot === false) {
                      form.setFieldsValue({
                        status: "requested",
                      });
                    }
                  } else {
                    set_assetSelected(0);
                    form.setFieldsValue({
                      status: "open",
                    });
                  }
                }}
                treeData={filtredAsset}
              />
            </Form.Item>

            {!asset && (
              <RequirePermission
                requiredPermissionCode={["manage_asset"]}
                children={
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => set_assetModalVisible(true)}
                    type="primary"
                    shape="circle"
                    style={{ marginTop: 3, float: "right" }}
                  />
                }
              />
            )}
          </Space>

          <Form.Item
            rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
            label={t(TRANSLATION_KEY.description)}
            name={"description"}
          >
            <Input.TextArea ref={inputDescriptionRef} disabled={false} rows={3} />
          </Form.Item>

          <div
            style={{
              marginBottom: 24,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Button
              style={{ display: "block" }}
              block
              type="dashed"
              onClick={() => set_visibleAditionalFields(!visibleAditionalFields)}
            >
              {visibleAditionalFields ? t(TRANSLATION_KEY.close) : t(TRANSLATION_KEY.addMoreData)}
            </Button>
          </div>

          <Row>
            <Col style={{ paddingRight: 3 }} span={12}>
              <Form.Item
                style={{ display: visibleAditionalFields ? "" : "none" }}
                name="subassets"
                label={t(TRANSLATION_KEY.assetMarks)}
              >
                <Select
                  allowClear
                  mode="multiple"
                  disabled={!assetSelected}
                  loading={getSubAssetsStatus === "loading"}
                >
                  {subAssets.map((x) => (
                    <Option key={x.id} value={x.id}>
                      {x.name}
                    </Option>
                  ))}
                  {hasPermission(user?.account?.permissions || [], ["manage_asset"]) && (
                    <Select.OptGroup
                      label={
                        <ButtonAddSelectTag
                          addingVisible={!!addSubAssetVisible}
                          set_addingVisible={() =>
                            set_addSubAssetVisible({
                              id: 0,
                              name: "",
                              asset: { id: 0, name: "" },
                            })
                          }
                        />
                      }
                    />
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ display: visibleAditionalFields ? "" : "none" }}
                label={t(TRANSLATION_KEY.maintenanceCategory)}
                name="maintenance_categories"
              >
                <Select disabled={false} mode="multiple">
                  {maintenanceCategories.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {t(x.trans_key || x.name)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ display: visibleAditionalFields ? "" : "none" }}
                label={t(TRANSLATION_KEY.plannedStart)}
                name={"planned_start"}
              >
                <DatePicker
                  format={user.account.date_format || "DD.MM.YYYY - HH:mm"}
                  style={{ width: "100%" }}
                  showTime={{
                    defaultValue: moment("08:00", "HH:mm"),
                  }}
                />
              </Form.Item>
            </Col>
            <Col style={{ paddingLeft: 3 }} span={12}>
              <Form.Item
                style={{ display: visibleAditionalFields ? "" : "none" }}
                label={t(TRANSLATION_KEY.maintenanceTypes)}
                name={"maintenance_type"}
              >
                <Select
                  allowClear
                  onChange={(val: number | undefined) => {
                    set_selectedMaintenanceType(val);
                  }}
                  disabled={false}
                >
                  {maintenancesTypes.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {t(x.trans_key || x.name)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {showLocationInput && (
                <Form.Item
                  style={{ display: visibleAditionalFields ? "" : "none" }}
                  name={"location"}
                  label={t(TRANSLATION_KEY.location)}
                >
                  <Select
                    disabled={false}
                    allowClear={true}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (typeof option?.children === "string") {
                        const str: string = option.children;
                        return str.toLowerCase().includes(input.toLowerCase());
                      }
                      return false;
                    }}
                    showSearch={true}
                    notFoundContent={
                      <ButtonAddSelectTag
                        addingVisible={addLocationVisible}
                        set_addingVisible={set_addLocationVisible}
                      />
                    }
                  >
                    {locations.map((x) => (
                      <Select.Option value={x.id} key={x.id}>
                        {x.name}
                      </Select.Option>
                    ))}
                    <Select.OptGroup
                      label={
                        <ButtonAddSelectTag
                          addingVisible={addLocationVisible}
                          set_addingVisible={set_addLocationVisible}
                        />
                      }
                    />
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                style={{ display: visibleAditionalFields ? "" : "none" }}
                label={t(TRANSLATION_KEY.checklist)}
                name={"checklist"}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (typeof option?.children === "string") {
                      const str: string = option.children;
                      return str.toLowerCase().includes(input.toLowerCase());
                    }
                    return false;
                  }}
                >
                  {checklists.map((x) => (
                    <Select.Option value={x.id} key={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col style={{ paddingRight: 3 }} span={12}>
              <Form.Item
                style={{ display: visibleAditionalFields ? "" : "none" }}
                label={t(TRANSLATION_KEY.timeNeeded)}
              >
                <Space>
                  <Form.Item name="hh">
                    <InputNumber style={{ width: 99 }} addonAfter="h" min={0} />
                  </Form.Item>
                  <Form.Item name="mm">
                    <InputNumber style={{ width: 99 }} addonAfter="m" max={59} min={0} />
                  </Form.Item>
                </Space>
              </Form.Item>
            </Col>
            <Col style={{ paddingLeft: 3 }} span={12}>
              <Form.Item
                style={{ display: visibleAditionalFields ? "" : "none" }}
                name={"deadline"}
                label={t(TRANSLATION_KEY.deadline)}
              >
                <DatePicker
                  format={user.account.date_format || "DD.MM.YYYY - HH:mm"}
                  showTime={true}
                  showSecond={false}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Spin
            style={{ display: visibleAditionalFields ? "" : "none" }}
            spinning={
              getPotentialExecutorsStatus === "loading" ||
              getPartnersStatus === "loading" ||
              getSuppliersStatus === "loading"
            }
          >
            <Form.Item
              style={{ display: visibleAditionalFields ? "" : "none" }}
              label={t(TRANSLATION_KEY.executors)}
              name={"executors"}
            >
              <Select
                allowClear
                disabled={false}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (typeof option?.children === "string") {
                    const str: string = option.children;
                    return str.toLowerCase().includes(input.toLowerCase());
                  }
                  return false;
                }}
                showSearch={true}
                mode={executorType === "employee" ? "multiple" : undefined}
                onSelect={(val: string) => {
                  let type = val.split("|").pop();
                  unstable_batchedUpdates(() => {
                    set_selectedExecutor(val);
                    if (type === "e") {
                      set_executorType("employee");
                    } else if (type === "s") {
                      set_supplierEmailVisible(true);
                      let email: string =
                        potentialExecutors.suppliers.find((x) => x.id === +val.split("|")[0])
                          ?.email || "";
                      emailForm.setFieldsValue({ sendEmail: email });
                      set_executorType("supplier");
                    } else {
                      set_executorType("partner");
                    }
                  }, []);
                }}
              >
                <OptGroup label={t(TRANSLATION_KEY.employee)}>
                  {potentialExecutors.employees.map((x) => (
                    <Option key={x.id + "|e"} value={x.id + "|e"}>
                      {x.name}
                    </Option>
                  ))}
                </OptGroup>
                <OptGroup label={t(TRANSLATION_KEY.supplier)}>
                  {potentialExecutors.suppliers.map((x) => (
                    <Option key={x.id + "|s"} value={x.id + "|s"}>
                      {x.name}
                    </Option>
                  ))}
                </OptGroup>
                {/* <OptGroup label={TRANSLATION_KEY.partner}>
                 {potentialExecutors.partners.map((x) => (
                    <Option key={x.id + "|p"} value={x.id + "|p"}>
                      {x.name}
                    </Option>
                  ))}
                  </OptGroup> */}
              </Select>
            </Form.Item>
          </Spin>

          <Form.Item
            style={{ display: visibleAditionalFields ? "" : "none" }}
            name="status"
            label={t(TRANSLATION_KEY.status)}
          >
            <Select>
              {STATUSES.filter((status) => !STATUSES_TO_IGNORE.includes(status)).map((x) => (
                <Option key={x} value={x}>
                  {t(x)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div style={{ display: visibleAditionalFields ? "" : "none" }}>
            {companyCustomFieldsV2 && (
              <GenerateForCustomFieldsV2
                values={{}}
                condition={selectedmaintenanceType}
                customFields={companyCustomFieldsV2.workorder}
                form={form}
              />
            )}

            <div style={{ height: 24 }}></div>

            <Divider plain>
              <div style={{ opacity: 0.65, fontSize: 12 }}>{t(TRANSLATION_KEY.files)}</div>
            </Divider>

            <div className="spaceBetweenRow" style={{ marginBottom: 24 }}>
              <Typography.Title level={5}>{t(TRANSLATION_KEY.documents)}</Typography.Title>
              <Upload
                multiple={true}
                accept={MATERIAL_EXTENSIONS}
                beforeUpload={beforeUpload}
                showUploadList={false}
              >
                <Button type="primary" shape="circle" icon={<UploadOutlined />} />
              </Upload>
            </div>

            {/* Files list */}
            <FilesComponent
              files={previewFiles}
              onDelete={onRemove}
              hideEditButton
              size="small"
              layout="list"
            />

            <div style={{ height: 24 }}></div>
          </div>
          <Form.Item>
            <Space>
              <Button
                onClick={() => {
                  set_loadingSave("save");
                }}
                loading={createFullOrderStatus === "loading" && loadingSave === "save"}
                htmlType="submit"
                type="primary"
              >
                {t(TRANSLATION_KEY.save)}
              </Button>
              <Button
                onClick={() => {
                  set_loadingSave("saveAndAdd");
                }}
                loading={createFullOrderStatus === "loading" && loadingSave === "saveAndAdd"}
                htmlType="submit"
                type="primary"
              >
                {t(TRANSLATION_KEY.saveAndAdd)}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
      <Modal
        visible={!!addSubAssetVisible}
        onCancel={() => {
          set_addSubAssetVisible(undefined);
        }}
        footer={null}
        centered
        width="600px"
        closable
        destroyOnClose
        title={t(TRANSLATION_KEY.addAssetMark)}
      >
        <SubAssetForm
          callback={(id: number) => {
            let valueFromForm = form.getFieldValue("subassets");
            let tmp: number[] = [];
            if (Array.isArray(valueFromForm)) {
              tmp = [...valueFromForm];
            }
            tmp.push(id);
            form.setFieldsValue({ subassets: tmp });
          }}
          asset_id={form.getFieldValue("asset")}
          close={() => set_addSubAssetVisible(undefined)}
          subAsset={addSubAssetVisible}
        />
      </Modal>

      {memedAddingAsset}

      <SelectTagAdd
        title={t(TRANSLATION_KEY.addLocation)}
        url="settings/locations/"
        set_visible={set_addLocationVisible}
        visible={addLocationVisible}
        successCallback={(tag: Tag) => {
          const data = [...locations];
          data.push({ id: tag.id, name: tag.name });
          const resFake: IApiResponse<ILocation[]> = {
            message: "",
            results: data,
          };

          dispatch(settingsSlice.actions.getLocationsSuccess(resFake));

          form.setFieldsValue({ location: tag.id });
        }}
      />
    </div>
  );
};

export default MaintenanceForm;

//kada treba slati na backend
export function createExecutorsObject(
  type: EXECUTOR_TYPES,
  _executors: string[] | string,
  send_email: string | null,
  note: string,
) {
  let executors: IFormExecutors = {
    supplier: null,
    employees: null,
    partner: null,
  };

  if (type === "partner" && !Array.isArray(_executors)) {
    executors.partner = +_executors.split("|")[0];
  } else if (type === "employee" && Array.isArray(_executors)) {
    executors.employees = _executors.map((x) => +x.split("|")[0]);
  } else if (type === "supplier" && !Array.isArray(_executors)) {
    executors.supplier = {
      supplier: +_executors.split("|")[0],
      send_email: send_email,
      note: note || "",
    };
  }

  return executors;
}
