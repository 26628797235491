import { UploadOutlined } from "@ant-design/icons";
import { Button, Divider, Empty, message, Spin, Typography, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FilesComponent, { IFile, MediaType } from "../../components/Files";
import { MATERIAL_EXTENSIONS, TRANSLATION_KEY, UPLOAD_FILES_LIMITS } from "../../helpers/consts";
import { calculateTimeout, filterFilesByExtension } from "../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IApiResponse } from "../../models";
import { IClientDetails } from "../../models/clients";
import api, { failedQueue, isRefreshing } from "../../services";
import { clientsSlice } from "../../store/reducers/clients";

// Search data
export type Data = IFile & { search: string };
const mapData = (materials: IFile[]): Data[] => {
  return materials.map((x) => ({
    ...x,
    search: x.name.toLowerCase(),
  }));
};

const NewMaterials: React.FC = () => {
  // Variables
  const { id } = useParams();
  const [search, set_search] = useState<string>("");
  const [filters, set_filters] = useState<MediaType[]>([]);
  const [loading, set_loading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [data, set_data] = useState<Data[]>([]);
  const [files, set_files] = useState<RcFile[]>([]);
  const [fileNames, set_fileNames] = useState<string[]>([]);
  const [uploadTrigger, set_uploadTrigger] = useState<boolean>(false);

  // Fetch data
  const { client } = useAppSelector((state) => state.clientsReducer);

  useEffect(() => {
    set_data(mapData(client.materials));
  }, [client.materials]);

  useEffect(() => {
    // If uploadTrigger is true, call onUpload function
    if (uploadTrigger) {
      onUpload();
    }
  }, [uploadTrigger]);

  const beforeUpload = (file: RcFile, fileList: RcFile[]) => {
    if (file.size / 1024 / 1024 > UPLOAD_FILES_LIMITS.maxFileSize) {
      message.error(t(TRANSLATION_KEY.maxFileSize));
      return Upload.LIST_IGNORE; // Prevent adding the file to the list
    }

    // Update files and fileNames state
    set_files((prevFiles) => [...prevFiles, file]);
    set_fileNames((prevNames) => [...prevNames, file.name]);
    set_uploadTrigger(true);

    return Upload.LIST_IGNORE;
  };

  const onUpload = async () => {
    // Reseting uploadTrigger
    set_uploadTrigger(false);
    set_loading(true);
    const token = await localStorage.getItem("token");
    const formData = new FormData();

    files.forEach((element: RcFile) => {
      formData.append("files", element);
    });

    const timeout = calculateTimeout(files);

    try {
      let response = await api.post<IApiResponse<IClientDetails>>(
        `clients/clients/${id}/add_materials/`,
        formData,
        { timeout, headers: { Authorization: "Bearer " + token } },
      );
      dispatch(
        clientsSlice.actions.getClientSuccess({
          message: "",
          results: response.data.results,
        }),
      );
      set_fileNames([]);
      set_files([]);
      message.destroy();
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onUpload());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_loading(false);
  };

  const onDeleteFile = async (id: number) => {
    set_loading(true);
    let token = await localStorage.getItem("token");

    let resp = api.delete<IApiResponse<string>>(`clients/clients/${id}/delete_material/`, {
      headers: { Authorization: "Bearer " + token },
    });
    let index = client.materials.findIndex((x) => x.id === id);
    let tmp = [...client.materials];
    tmp.splice(index, 1);

    dispatch(
      clientsSlice.actions.getClientSuccess({
        message: "",
        results: {
          ...client,
          materials: tmp,
        },
      }),
    );
    // Request

    set_loading(false);
  };

  // Filters and search
  let filtredData: IFile[] = data;
  if (search) filtredData = data?.filter((x) => x.search.includes(search));
  if (filters.length) filtredData = filterFilesByExtension(filtredData, filters);

  return (
    <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
      <div className="spaceBetweenRow" style={{ paddingTop: 10 }}>
        {/* Title */}
        <Typography.Title level={5}>{t(TRANSLATION_KEY.materials)}</Typography.Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 12,
          }}
        >
          <Upload
            showUploadList={false}
            beforeUpload={beforeUpload}
            accept={MATERIAL_EXTENSIONS}
            multiple
          >
            <Button loading={loading} type="primary" icon={<UploadOutlined />} shape="circle" />
          </Upload>
        </div>
      </div>

      <Divider style={{ marginTop: 10 }} />

      <Spin spinning={loading}>
        {data.length > 0 ? (
          <FilesComponent
            files={data}
            onDelete={onDeleteFile}
            hideEditButton
            layout="grid"
            size="small"
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: 70 }} />
        )}
      </Spin>
    </div>
  );
};

export default NewMaterials;
