import { useEffect } from "react";
import { CustomFieldV2, CustomFieldValueType, CustomFieldValues } from "../models/settings";
import {
  Checkbox,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Typography,
  message,
} from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../helpers/consts";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useForm } from "antd/es/form/Form";
import moment, { Moment } from "moment";
import { IUser } from "../models/user";

const { Item } = Form;

interface IProps {
  condition?: number;
  values: CustomFieldValues | undefined;
  customFields: CustomFieldV2[];
  form: FormInstance<any>;
}

const GenerateForCustomFieldsV2: React.FC<IProps> = ({ customFields, condition, values, form }) => {
  const { user } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (values === undefined) {
      return;
    }
    let customFieldsValues: { [key: string]: CustomFieldValueType | Moment } = {};
    Object.keys(values || {}).forEach((key) => {
      if (values[key].type === "datetime") {
        console.log(values[key].value);
        customFieldsValues[key] =
          typeof values[key].value === "string" ? moment(values[key].value as string) : undefined;
      } else {
        customFieldsValues[key] = values[key].value;
      }
    });
    if (values) {
      form.setFieldsValue(customFieldsValues as any);
    }
  }, [values]);

  return (
    <>
      {getCustomFieldsByCondition(customFields, condition).map((x, index) => (
        <Item
          valuePropName={x.type === "flag" ? "checked" : undefined}
          key={index}
          name={x.cf_id}
          label={x.name}
        >
          {getCustomField(x, user)}
        </Item>
      ))}
    </>
  );
};
export default GenerateForCustomFieldsV2;

export const getCustomFieldsByCondition = (
  customFields: CustomFieldV2[],
  condition: number | undefined,
) => {
  let tmpCustomCustomFields = [...customFields];
  if (!condition) {
    tmpCustomCustomFields = tmpCustomCustomFields.filter((x) => x.condition?.length === 0);
  } else if (condition) {
    tmpCustomCustomFields = tmpCustomCustomFields.filter(
      (x) => x.condition?.length === 0 || x.condition?.includes(condition),
    );
  }

  return tmpCustomCustomFields;
};

const getCustomField = (field: CustomFieldV2, user: IUser) => {
  switch (field.type) {
    case "text":
      return <Input />;
    case "number":
      return <InputNumber />;
    case "select":
      return (
        <Select mode="multiple">
          {field.data?.map((x, index) => (
            <Select.Option key={index} value={x}>
              {x}
            </Select.Option>
          ))}
        </Select>
      );
    case "datetime":
      return (
        <DatePicker format={user.account.date_format || "DD.MM.YYYY - HH:mm"} showTime={true} />
      );
    case "flag":
      return <Checkbox />;

    default:
      return (
        <Typography.Text type="danger">
          {t(TRANSLATION_KEY.customFieldTypeDontExsits)}
        </Typography.Text>
      );
  }
};

export const customValuesCollector = (
  data: { [key: string]: any },
  definitions: CustomFieldV2[],
) => {
  let customFields: { [key: string]: any } = {};
  Object.keys(data).forEach((key) => {
    if (key.includes("cf_")) {
      let _definition = definitions.find((x) => x.cf_id === key);
      if (!_definition) {
        console.log("Definition not found for key: ", key);
        return;
      }
      if (_definition.type === "datetime") {
        if (data[key]) {
          customFields[_definition.cf_id] = moment(data[key]).toISOString();
        } else {
          customFields[_definition.cf_id] = null;
        }
      } else if (_definition.type === "flag") {
        customFields[_definition.cf_id] = data[key] === true;
      } else {
        customFields[_definition.cf_id] = data[key] || null;
      }
    }
  });
  return customFields;
};
