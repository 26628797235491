import {
  Button,
  Divider,
  Drawer,
  Modal,
  PageHeader,
  Popconfirm,
  Space,
  Spin,
  Tabs,
  Typography,
  message,
} from "antd";
import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import ClientForm, { CreateClient } from "../../componentsform/ClientForm";
import RentForm from "../../componentsform/RentForm";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IClient, IRent } from "../../models/clients";
import { AppRoutes } from "../../navigation/routes";
import api, { failedQueue, isRefreshing } from "../../services";
import { getClientXHR, updateClientXHR } from "../../store/reducers/clients/actionCreators";
import { colors } from "../../styles/colors";
import ClientSpec from "./ClientSpec";
import DrawerAsset from "./components/Drawer/Asset";
import Materials from "./Materials";
import { CustomFieldValues } from "../../models/settings";
import RentForm_v2 from "../../componentsform/RentForm_v2";
import NewMaterials from "./NewMaterials";

interface IProps {}

const Client: React.FC<IProps> = ({}) => {
  // Hooks
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Variables
  const { user } = useAppSelector((state) => state.userReducer);
  const { client, getClientStatus, updateClientStatus } = useAppSelector(
    (state) => state.clientsReducer,
  );
  const [addNew, set_addNew] = React.useState<IRent>();
  const [editVisible, set_editVisible] = React.useState<boolean>(false);
  const [delLoading, set_delLoading] = React.useState<boolean>(false);
  const [modalVisible, setModalVisible] = React.useState<IClient | undefined>(undefined);
  const [activeKey, set_activeKey] = useState<string>(location.pathname.split("/")[4]);

  // Functions
  useEffect(() => {
    getClientXHR(
      {
        id,
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
      },
      dispatch,
    );
  }, [id]);

  const onDelete = async () => {
    set_delLoading(true);
    let token = await localStorage.getItem("token");
    try {
      await api.delete(`clients/clients/${id}/`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      navigate("/app/clients");
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_delLoading(false);
  };

  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
    set_activeKey(location.pathname.split("/")[4]);
  };

  const archive = (flag: boolean) => {
    let values: any = {
      is_archived: flag,
    };
    updateClientXHR(
      {
        body: values,
        id: client.id,
        errorCallback: (err) => {
          if (err.response?.data?.message?.custom_field) {
            message.error(
              t(err.response?.data?.message.message || "") +
                " \n" +
                err.response?.data?.message.custom_field,
            );
            return;
          }
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: () => {
          close();
        },
      },
      dispatch,
    );
  };

  const memedTabs = useMemo(() => {
    if (!client.id) return null;
    return (
      <>
        <Tabs id="bordered-tabs" activeKey={activeKey} onChange={onTabChange}>
          {AppRoutes("allroutes")
            .children?.find((x) => x.path === "clients/:id")
            ?.children?.filter((x) => {
              //provjeriti ukoliko nije definirana permisija more
              //ukoliko ima permisija viditi dali ima korisnik
              if (x.permissionCode === undefined) {
                return true;
              }
              return user.account.permissions.map((x) => x.codename).includes(x.permissionCode);
            })
            .map((x) => (
              <Tabs.TabPane
                tab={
                  <Link style={{ color: colors.globalFontColor }} to={x.path}>
                    {t(x.path || "rentOrders")}
                  </Link>
                }
                key={x.path}
              />
            ))}
        </Tabs>

        <Spin spinning={getClientStatus === "loading"}>
          <Outlet />
        </Spin>
      </>
    );
  }, [client.id]);

  return (
    <>
      <Spin spinning={getClientStatus === "loading"}>
        <PageHeader
          style={{ padding: 0, paddingTop: 10 }}
          className="custom-page-header"
          onBack={() => navigate("/app/clients")}
          subTitle={
            <div>
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.client)} </Typography.Text>
              <Typography.Text strong>#{client.custom_id}</Typography.Text>
            </div>
          }
          extra={[
            <Button
              style={{
                display: client.is_archived ? "none" : "block",
              }}
              onClick={() =>
                set_addNew({
                  custom_id: "",
                  id: 0,
                  asset: undefined,
                  created_at: "",
                  closed_at: null,
                  created_by: null,
                  closed_by: null,
                  real_end: null,
                  order_type: "rent",
                  description: "",
                  status: "active",
                  location: {
                    id: 0,
                    name: "",
                  },
                  client: undefined,
                  rent_order_custom_fields_v2: {},
                  notes: [],
                })
              }
              type="primary"
            >
              {t(TRANSLATION_KEY.rent)}
            </Button>,
            <Button
              loading={updateClientStatus === "loading"}
              onClick={() => archive(!client.is_archived)}
            >
              {client.is_archived ? t(TRANSLATION_KEY.return) : t(TRANSLATION_KEY.archive)}
            </Button>,
            <Popconfirm
              onCancel={() => {}}
              onConfirm={onDelete}
              title={t(TRANSLATION_KEY.continueWithAction)}
              cancelText={t(TRANSLATION_KEY.no)}
              okText={t(TRANSLATION_KEY.yes)}
            >
              <Button loading={delLoading} type="primary" danger>
                {t(TRANSLATION_KEY.delete)}
              </Button>
            </Popconfirm>,
            <Button onClick={() => set_editVisible(true)} type="primary">
              {t(TRANSLATION_KEY.edit)}
            </Button>,
          ]}
        >
          <Space direction="vertical">
            <Space size="large" align="center">
              <Typography.Title level={3}>{client.name}</Typography.Title>
            </Space>
            {/* Specifications */}
            <ClientSpec />
          </Space>
        </PageHeader>
      </Spin>

      {/* Divider */}
      <Divider />

      {/* Materials */}
      <NewMaterials />

      <div style={{ marginTop: 12 }} />

      {/* Tabs */}
      {memedTabs}
      <Modal
        visible={!!modalVisible}
        onCancel={() => {
          setModalVisible(undefined);
        }}
        footer={null}
        centered
        title={t(TRANSLATION_KEY.rentAssetTitle)}
        width={1200}
        closable
        destroyOnClose
      >
        {modalVisible && (
          <RentForm client={modalVisible} close={() => setModalVisible(undefined)} />
        )}
      </Modal>

      <Drawer
        width={540}
        visible={editVisible}
        onClose={() => set_editVisible(false)}
        title={t(TRANSLATION_KEY.editClient)}
        destroyOnClose={true}
      >
        <ClientForm close={() => set_editVisible(false)} client={client} />
      </Drawer>
      <Drawer
        destroyOnClose
        title={t(TRANSLATION_KEY.newRentOrder)}
        width={540}
        onClose={() => {
          set_addNew(undefined);
        }}
        visible={!!addNew}
      >
        <RentForm_v2
          client={client.id}
          onClose={() => {
            set_addNew(undefined);
          }}
        />
      </Drawer>
    </>
  );
};

export default Client;

function convertCustomFields(fields: CustomFieldValues) {
  const convertedFields: Record<string, any> = {};

  for (const key in fields) {
    if (fields.hasOwnProperty(key)) {
      convertedFields[key] = { cf_id: fields[key].value };
    }
  }

  return convertedFields;
}
