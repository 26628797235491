import { message } from "antd";
import { t } from "i18next";
import { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { TRANSLATION_KEY } from "./helpers/consts";
import { useAppDispatch, useAppSelector } from "./hooks";

import RoutesGenerator from "./navigation/RoutesGenerator";
import { getAssetCategoriesXHR } from "./store/reducers/asstes/actionCreators";
import {
  getMaintenancesCategoriesXHR,
  getMaintenancesTypesXHR,
  getStopWorkingReasonsXHR,
} from "./store/reducers/maintenance/actionCreator";
import { getCustomFieldsV2XHR, getLocationsXHR } from "./store/reducers/settings/actionCreator";
import { getPartsCategoriesXHR } from "./store/reducers/warehouse/actionCreator";
import RouterScrollWrapper from "./components/RouterScrollWrapper";

const AppWraper = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userReducer);

  // Methods
  useEffect(() => {
    if (user.token.access) {
      getAllCategoriesTypesEtc();
    }
  }, [user.token.access]);

  // somethin like prepare in previous apps
  const getAllCategoriesTypesEtc = () => {
    getStopWorkingReasonsXHR({}, dispatch);

    getAssetCategoriesXHR({}, dispatch);
    getMaintenancesCategoriesXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
    getMaintenancesTypesXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );

    getLocationsXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
    getPartsCategoriesXHR({}, dispatch);
    getCustomFieldsV2XHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
      },
      dispatch,
    );

    // getWorkRequestsXHR({
    //   errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
    // }, dispatch);
  };

  return (
    <Router>
      <RouterScrollWrapper>
        <RoutesGenerator />
      </RouterScrollWrapper>
    </Router>
  );
};

export default AppWraper;
