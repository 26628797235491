import React, { useEffect, useState } from "react";
import { Table, TableColumnType, TableProps } from "antd";
import { useAppSelector } from "../hooks";
import { CustomTableColumn, filterColumns } from "./ViewTabs";

// Define your custom props
interface CustomTableProps<RecordType> extends TableProps<RecordType> {
  columns: Array<Partial<CustomTableColumn> & TableColumnType<RecordType>>;
}

// Create your custom table component
const CustomAntdTable = <RecordType extends object = any>({
  columns,
  ...restProps
}: CustomTableProps<RecordType>) => {
  const { getCustomFieldsV2Status } = useAppSelector((state) => state.settingsReducer);

  const [COLUMNS, set_COLUMNS] = useState<any>([]);

  useEffect(() => {
    set_COLUMNS(filterColumns<any>([...columns]));
  }, [getCustomFieldsV2Status, columns]);

  if (getCustomFieldsV2Status === "loading") {
    return <Table {...restProps} columns={COLUMNS} dataSource={[]} />;
  }

  return (
    <Table
      scroll={{
        x: columns.length * 120,
      }}
      {...restProps}
      columns={COLUMNS}
    />
  );
};

export default CustomAntdTable;
