import React, { useRef, useEffect, useState } from "react";

// Styles
import { Carousel } from "antd";
import styles from "../../index.module.css";
import {
  RightOutlined,
  LeftOutlined,
  ArrowLeftOutlined,
  FileImageFilled,
  FilePdfFilled,
  VideoCameraFilled,
  FileExclamationFilled,
} from "@ant-design/icons";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { MOB_SIZE } from "../../../../pages/openorder/components/Order";

interface MediaFile {
  file: string;
  name: string;
  type: string;
  extension: string;
}

interface IProps {
  files: MediaFile[];
  index: number;
  onClose: (v: any) => void;
}

const FileViewerComponent: React.FC<IProps> = ({ files, index, onClose }) => {
  // Refs
  const carouselRef = useRef<any>(null);
  const videoRef = useRef<any>(null);
  const [localIndex, setLocalIndex] = useState<number>(index);

  useEffect(() => {
    carouselRef.current.goTo(index, false);
  }, []);

  const onChange = (index: number): void => {
    //
  };

  const PreviousArrow = (props: any) => {
    const { className, style, onClick } = props;

    function handleClick(event: any) {
      if (localIndex === 0) setLocalIndex(files.length - 1);
      else setLocalIndex((previousIndex) => --previousIndex);
      event.stopPropagation();
      onClick();
    }

    return (
      <div
        className={`${className} ${styles.fileViewerCarouselPrevArrow}`}
        style={{
          ...style,
          fontSize: "18px",
          lineHeight: "0",
          paddingLeft: "-20px",
          paddingTop: "16px",
        }}
        onClick={handleClick}
      >
        <LeftOutlined style={{ color: "#fff", transform: "translateX(-1px)" }} />
      </div>
    );
  };

  const NextArrow = (props: any) => {
    const { className, style, onClick } = props;

    function handleClick(event: any) {
      if (localIndex === files.length - 1) setLocalIndex(0);
      else setLocalIndex((previousIndex) => ++previousIndex);
      event.stopPropagation();
      onClick();
    }

    return (
      <div
        className={`${className} ${styles.fileViewerCarouselNextArrow}`}
        style={{
          ...style,
          fontSize: "18px",
          lineHeight: "0",
          paddingLeft: "16px",
          paddingTop: "16px",
        }}
        onClick={handleClick}
      >
        <RightOutlined style={{ color: "#fff" }} />
      </div>
    );
  };

  const settings = {
    prevArrow: <PreviousArrow />,
    nextArrow: <NextArrow />,
  };

  const typeIcon = {
    image: <FileImageFilled style={{ color: "#e50a0a", fontSize: 18, marginRight: 12 }} />,
    document: <FilePdfFilled style={{ color: "#0353ef", fontSize: 18, marginRight: 12 }} />,
    video: <VideoCameraFilled style={{ color: "#02b111", fontSize: 18, marginRight: 12 }} />,
    invalid: <FileExclamationFilled style={{ color: "#f09001", fontSize: 18, marginRight: 12 }} />,
  };

  const currentFile = files[localIndex];
  const _type = currentFile.type as keyof typeof typeIcon;
  const title = currentFile.name;

  useEffect(() => {
    if (files.some((item) => item.type === "video")) {
      if (currentFile.type !== "video") videoRef.current.pause();
      else videoRef.current.play();
    }
  }, [localIndex]);

  return (
    <div key={index} className={styles.fileViewerContainer} id="files-modal">
      <div className={styles.fileViewerToolbar}>
        <div className={styles.fileViewerToolbarLeftSide}>
          {/* Arrow */}
          <ArrowLeftOutlined
            onClick={onClose}
            style={{
              color: "#fff",
              fontSize: 20,
              marginRight: 16,
              opacity: 0.65,
            }}
          />
          {/* Icon */}
          {typeIcon[_type]}
          {/* File name */}
          <div
            style={{
              color: "#fff",
              fontWeight: 600,
              fontSize: 16,
              marginBottom: -2,
            }}
          >
            {title}
          </div>
        </div>

        {/* File index */}
        <div
          style={{
            color: "#fff",
            fontSize: 16,
            marginBottom: -2,
            fontWeight: 600,
          }}
        >
          {`${localIndex + 1} / ${files.length}`}
        </div>
      </div>
      <div className={styles.fileViewerCarouselWrapper} onClick={onClose}>
        <div className={styles.fileViewerCarousel} onClick={(event) => event.stopPropagation()}>
          <Carousel
            ref={carouselRef}
            dots={true}
            dotPosition="bottom"
            afterChange={onChange}
            effect="fade"
            arrows={true}
            {...settings}
          >
            {files.map((item, idx) =>
              item.type === "image" ? (
                window.innerWidth >= MOB_SIZE ? (
                  <TransformWrapper key={idx}>
                    <TransformComponent>
                      <div className={styles.fileViewerCarouselItemWrapper}>
                        <img src={item.file} className={styles.fileViewerCarouselImageWrapper} />
                      </div>
                    </TransformComponent>
                  </TransformWrapper>
                ) : (
                  <div className={styles.fileViewerCarouselItemWrapper}>
                    <img src={item.file} className={styles.fileViewerCarouselImageWrapper} />
                  </div>
                )
              ) : item.type === "video" ? (
                <div key={idx} className={styles.fileViewerCarouselItemWrapper}>
                  <video
                    ref={videoRef}
                    key={idx}
                    controls={true}
                    width="100%"
                    height="100%"
                    preload="metadata"
                    style={{ objectFit: "contain" }}
                  >
                    <source src={item.file} type="video/mp4" />
                  </video>
                </div>
              ) : item.type === "document" ? (
                window.innerWidth < MOB_SIZE ? (
                  // Cannot display pdf on mobile
                  <div key={idx} className={styles.fileViewerCarouselItemFallback}>
                    {t(TRANSLATION_KEY.erroFileLoad)}
                  </div>
                ) : (
                  <div key={idx} className={styles.fileViewerCarouselItemWrapper}>
                    <embed
                      src={item.file}
                      className={styles.document}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    />
                  </div>
                )
              ) : (
                <div key={idx} className={styles.fileViewerCarouselItemFallback}>
                  {t(TRANSLATION_KEY.erroFileLoad)}
                </div>
              ),
            )}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default FileViewerComponent;
