import { Button, Input, InputNumber, Space } from "antd";
import React, { useEffect } from "react";

export type SearchValue = {
  from: number | undefined | string;
  to: number | undefined | string;
};

interface IProps {
  column_key: string;
  defaultSearchValue: SearchValue | undefined;
  close?: () => void;
  opened: string;
  onSave: (value: SearchValue, column_key: string) => void;
}

const NumberFilter: React.FC<IProps> = ({
  close,
  opened,
  defaultSearchValue,
  onSave,
  column_key,
}) => {
  const searchInputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [searchValue, set_searchValue] = React.useState<SearchValue>({
    from: defaultSearchValue?.from,
    to: defaultSearchValue?.to,
  });

  useEffect(() => {
    if (searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 300);
    }
  }, [opened]);

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <InputNumber
          ref={searchInputRef || undefined}
          placeholder={`Od`}
          defaultValue={defaultSearchValue?.from}
          onChange={(e) => set_searchValue({ ...searchValue, from: e || undefined })}
          onPressEnter={() => onSave(searchValue, column_key)}
          style={{ marginBottom: 8, marginLeft: 6 }}
        />
        <InputNumber
          placeholder={`Do`}
          defaultValue={defaultSearchValue?.to}
          onChange={(e) => set_searchValue({ ...searchValue, to: e || undefined })}
          onPressEnter={() => onSave(searchValue, column_key)}
          style={{ marginBottom: 8, marginLeft: 6 }}
        />

        <Button
          style={{ marginBottom: 8, marginLeft: 6 }}
          type="primary"
          onClick={() => onSave(searchValue, column_key)}
        >
          Pretraži
        </Button>
      </div>
    </div>
  );
};

export default NumberFilter;
