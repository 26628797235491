import {
  Button,
  Col,
  Descriptions,
  Image,
  List,
  message,
  Popconfirm,
  Row,
  Spin,
  Typography,
} from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import RequirePermission from "../../../components/RequirePermission";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Upload, { RcFile } from "antd/lib/upload";
import api, { failedQueue, isRefreshing } from "../../../services";
import { IApiResponse } from "../../../models";
import { assetSlice } from "../../../store/reducers/asstes";
import { IAsset } from "../../../models/asset";
import { getCompanyCusstomFieldsXHR } from "../../../store/reducers/settings/actionCreator";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import { parseCosts } from "../../../helpers/functions";
import CustomFieldsSpecGenerator from "../../../components/CustomFieldsSpecGenerator";

const AssetInfo: React.FC = () => {
  const beforeUpload = async (file: RcFile) => {
    set_avatarLoading(true);
    const token = await localStorage.getItem("token");

    const formData = new FormData();

    formData.append("files", file);

    try {
      let response = await api.post<IApiResponse<{ avatar: string }>>(
        `/assets/assets/${asset.id}/set_avatar/`,
        formData,
        { headers: { Authorization: "Bearer " + token } },
      );

      if (response.data.results) {
        const data = { ...asset };
        data.avatar = response.data.results.avatar;
        const fakeRes: IApiResponse<IAsset> = {
          message: "",
          results: data,
        };
        dispatch(assetSlice.actions.getSingleAssetSuccess(fakeRes));
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => beforeUpload(file));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }

    set_avatarLoading(false);
    return false;
  };

  const handleDelete = async () => {
    set_avatarLoading(true);
    const token = await localStorage.getItem("token");
    const formData = new FormData();
    // formData.append("files", file);
    try {
      let response = await api.post<IApiResponse<{ avatar: string }>>(
        `/assets/assets/${asset.id}/set_avatar/`,
        formData,
        { headers: { Authorization: "Bearer " + token } },
      );

      if (response.data.results) {
        const data = { ...asset };
        data.avatar = response.data.results.avatar;
        const fakeRes: IApiResponse<IAsset> = {
          message: "",
          results: data,
        };
        dispatch(assetSlice.actions.getSingleAssetSuccess(fakeRes));
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => handleDelete());
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
    }
    set_avatarLoading(false);
    return false;
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { asset } = useAppSelector((state) => state.assetReducer);
  const [avatarLoading, set_avatarLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getCompanyCusstomFieldsXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);

  const validate = (value: any) => (value != null ? value : "-"); // nepotrebno
  return (
    <Row>
      <Col span={24}>
        <div style={{ display: "flex" }}>
          {/* Image */}
          <div
            style={{ backgroundColor: "white", position: "relative", marginRight: 24, height: 132 }}
          >
            <Spin spinning={avatarLoading} style={{ width: 240, height: 132 }}>
              {asset.avatar ? (
                <Image
                  style={{ width: 240, height: 132, objectFit: "contain" }}
                  src={asset.avatar}
                />
              ) : (
                <Image
                  src="error"
                  style={{ width: 240, height: 132, objectFit: "contain" }}
                  fallback={require("../../../assets/icons/default.jpg")}
                />
              )}
            </Spin>
            <div style={{ position: "absolute", bottom: 10, right: 10 }}>
              <RequirePermission
                requiredPermissionCode={["manage_asset"]}
                children={
                  <Upload
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    accept=".jpeg,.jpg,.png"
                  >
                    <Button
                      type="primary"
                      shape="circle"
                      icon={asset.avatar ? <EditOutlined /> : <PlusOutlined />}
                    />
                  </Upload>
                }
              />
              {asset.avatar && (
                <Popconfirm
                  onConfirm={handleDelete}
                  title={t(TRANSLATION_KEY.continueWithAction)}
                  cancelText={t(TRANSLATION_KEY.no)}
                  okText={t(TRANSLATION_KEY.yes)}
                  placement="top"
                >
                  <Button
                    danger
                    type="primary"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    style={{ marginLeft: 12 }}
                  />
                </Popconfirm>
              )}
            </div>
          </div>
          <div>
            <Descriptions size="small" column={{ xxl: 3, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
              {/* Asset category */}
              <Descriptions.Item label={t(TRANSLATION_KEY.assetCategory)}>
                {validate(t(asset.category.name))}
              </Descriptions.Item>

              {/* Location */}
              {asset.location && (
                <Descriptions.Item label={t(TRANSLATION_KEY.location)}>
                  {validate(asset.location.name || t(TRANSLATION_KEY.locationNotDefined))}
                </Descriptions.Item>
              )}

              {/* Buy price */}
              {asset.buy_price && (
                <Descriptions.Item label={t(TRANSLATION_KEY.buyPrice)}>
                  <Typography.Text
                    ellipsis={{
                      tooltip: `${parseCosts(asset.buy_price?.toString())} ${
                        asset.buy_price_currency
                      } / ${parseCosts(asset.base_buy_price?.toString())} ${asset.base_currency}`,
                    }}
                    style={{ maxWidth: 280 }}
                  >
                    {validate(
                      `${parseCosts(asset.buy_price?.toString())} ${asset.buy_price_currency} / `,
                    )}
                    {validate(
                      `${parseCosts(asset.base_buy_price?.toString())} ${asset.base_currency}`,
                    )}
                  </Typography.Text>
                </Descriptions.Item>
              )}

              {/* Asset route */}
              {asset.path && (
                <Descriptions.Item label={t(TRANSLATION_KEY.assetRoute)}>
                  <Typography.Text style={{ maxWidth: 240 }} ellipsis={{ tooltip: asset.path }}>
                    {validate(asset.path)}
                  </Typography.Text>
                </Descriptions.Item>
              )}

              {/* Autopilot */}
              <Descriptions.Item label={t(TRANSLATION_KEY.autopilot)}>
                {asset.autopilot ? t(TRANSLATION_KEY.enabled) : t(TRANSLATION_KEY.disabled)}
              </Descriptions.Item>

              {/* Description */}
              {asset.description && (
                <Descriptions.Item label={t(TRANSLATION_KEY.description)}>
                  <Typography.Text style={{ width: 340 }} ellipsis={{ tooltip: asset.description }}>
                    {validate(asset.description)}
                  </Typography.Text>
                </Descriptions.Item>
              )}

              {asset.client_owner && (
                <Descriptions.Item label={t(TRANSLATION_KEY.ownedBy)}>
                  <Typography.Text
                    style={{ maxWidth: 240 }}
                    ellipsis={{ tooltip: asset.client_owner.name }}
                  >
                    {validate(asset.client_owner.name)}
                  </Typography.Text>
                </Descriptions.Item>
              )}

              {asset.rented_to_client && (
                <Descriptions.Item label={t(TRANSLATION_KEY.rentedBy)}>
                  <Typography.Text
                    style={{ maxWidth: 240 }}
                    ellipsis={{ tooltip: asset.rented_to_client.name }}
                  >
                    {validate(asset.rented_to_client.name)}
                  </Typography.Text>
                </Descriptions.Item>
              )}

              {/* Custom fields */}
              {CustomFieldsSpecGenerator({
                onChange: (val) => {
                  console.log(val);
                },
                condition: asset.category?.id,
                formType: "asset",
                data: asset.custom_fields_v2,
              })}
            </Descriptions>
          </div>
        </div>
      </Col>

      {/* QR Code */}
      <Col span={6} style={{ display: "none" }}>
        <div style={{ minHeight: "33vh" }} className="white-container">
          <Typography.Title level={4} style={{ paddingBottom: 8 }}>
            {t(TRANSLATION_KEY.qrcode)}
          </Typography.Title>
          <div ref={componentRef}>
            <style type="text/css" media="print">
              {`@page { size: 40mm 50mm;}
            @media all {
              .pagebreak {
                display: none;
              }
            }
            @media print {
              .pagebreak {
                page-break-before: always;
              }
            }`}
            </style>
            <QRCode size={200} value={window.location.href} />
          </div>
          <Button onClick={handlePrint} style={{ width: 200, marginTop: 12 }}>
            {t(TRANSLATION_KEY.printQrcode)}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default AssetInfo;
