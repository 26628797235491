import {
  Button,
  message,
  Modal,
  PageHeader,
  Popover,
  Space,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { LAYOUT_GRIDS, TRANSLATION_KEY } from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";

import {
  FieldTimeOutlined,
  FilePdfOutlined,
  PauseCircleTwoTone,
  SolutionOutlined,
} from "@ant-design/icons";
import DigitalSignatureModal from "../../components/DigitalSignature/Modal";
import useDigitalSignature from "../../components/DigitalSignature/useDigitalSignature";
import RequirePermission from "../../components/RequirePermission";
import ConfirmForm from "../../componentsform/ConfirmForm";
import { hasModul, hasPermission, openBase64PDF } from "../../helpers/functions";
import { IApiResponse } from "../../models";
import { IMaintenanceDetails, IUpdateMaintenance } from "../../models/maintenances";
import { AppRoutes } from "../../navigation/routes";
import api from "../../services";
import { dashboardSlice } from "../../store/reducers/dashboard";
import { maintenanceSlice } from "../../store/reducers/maintenance";
import {
  getMaintenanceXHR,
  updateMaintenanceXHR,
} from "../../store/reducers/maintenance/actionCreator";
import { colors } from "../../styles/colors";
import ConfirmOrder from "../maintenance/components/ConfirmOrder";
import AddWorkingTime from "./components/AddWorkingTime";
import Comands from "./components/comands";
import Reject from "./components/comands/reject";
import MaintenanceInfo from "./components/MaintenanceInfo";
import MaintenancePDF from "./components/MaintenancePDF";
import StartStop from "./components/StartStop";
import Timer from "./components/Timer";
import WorkRequestDetails from "./components/WorkRequestDetails";
import DigitalSignatureIFrame from "../../components/DigitalSignature/IFrame";
import moment from "moment";
const { TabPane } = Tabs;

export type Action = "open" | "download";
export type RenderType = "stream" | "file";

export interface PDFProps {
  maintenance: IMaintenanceDetails;
  avatar: string | undefined;
  language: string;
}

const Maintenance = ({}) => {
  // Variables
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [comandsVisible, set_comandsVisible] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.userReducer);
  const [confirmOrderModalVisible, set_confirmOrderModalVisible] = useState<number>();
  const [addWorkingTime, set_addWorkingTime] = useState<boolean>();
  const { getMaintenanceStatus, maintenanceDetails, liveMaintenances } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const [maintenanceDescription, setMaintenanceDescription] = useState(
    maintenanceDetails.description,
  );

  // Hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const digitalSignature = useDigitalSignature(maintenanceDetails.id);

  // Methods
  useEffect(() => {
    getMaintenanceXHR({ id, loading: "loading" }, dispatch);
  }, [id]);

  useEffect(() => {
    setMaintenanceDescription(maintenanceDetails.description);
  }, [maintenanceDetails.description]);

  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };

  function updateMaintenance(data: IUpdateMaintenance) {
    updateMaintenanceXHR(
      {
        body: data,
        id: maintenanceDetails.id,
        errorCallback: (err) => {
          if (err.response.data?.message?.custom_field) {
            message.error(
              t(err.response.data.message.message || "").replace(
                "$_dynamic_column",
                err.response.data.message.custom_field,
              ),
            );
            return;
          }
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: (data) => {
          if (data.results) {
            let maintenances = [...liveMaintenances];
            let index = maintenances.findIndex((m) => m.id === data.results?.id);
            maintenances.splice(index, 1, data.results);
            dispatch(
              maintenanceSlice.actions.getLiveMaintenancesSuccess({
                message: "",
                results: { data: maintenances, cursor: null },
              }),
            );
            dispatch(dashboardSlice.actions.set_categoriesTypes(maintenances));
          }
        },
      },
      dispatch,
    );
  }

  // Content
  let runningTime = maintenanceDetails.flows.find(
    (flow) => flow.end === null && flow.executor?.account?.id === user.account.id,
  )?.start;

  // Functions
  async function handleGetPDF() {
    const token = await localStorage.getItem("token");
    try {
      setPdfLoading(true);
      let response = await api.post<IApiResponse<string>>(
        `/maintenance/order_info/${maintenanceDetails.id}/generate_pdf/`,
        {
          maintenance: maintenanceDetails,
          avatar: user.account.company.avatar,
          language: user.account.language,
          dates: {
            planned_start: moment(maintenanceDetails.planned_start).isValid()
              ? moment(maintenanceDetails.planned_start).format()
              : null,
            planned_end: moment(maintenanceDetails.planned_end).isValid()
              ? moment(maintenanceDetails.planned_end).format()
              : null,
            real_start: moment(maintenanceDetails.real_start).isValid()
              ? moment(maintenanceDetails.real_start).format()
              : null,
            real_end: moment(maintenanceDetails.real_end).isValid()
              ? moment(maintenanceDetails.real_end).format()
              : null,
            deadline: moment(maintenanceDetails.deadline).isValid()
              ? moment(maintenanceDetails.deadline).format()
              : null,
            closed_at: moment(maintenanceDetails.closed_at).isValid()
              ? moment(maintenanceDetails.closed_at).format()
              : null,
            created_at: moment(maintenanceDetails.created_at).isValid()
              ? moment(maintenanceDetails.created_at).format()
              : null,
          },
        } as PDFProps,
        { headers: { Authorization: "Bearer " + token } },
      );
      if (response.data) {
        openBase64PDF(response.data.results, maintenanceDetails.description, () =>
          setPdfLoading(false),
        );
      }
    } catch (error: any) {
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
      setPdfLoading(false);
    }
  }

  return (
    <>
      {/* Place where digital signature iframe is rendering */}
      <DigitalSignatureIFrame digitalSignature={digitalSignature} />

      {/* Rest of the component */}
      <div>
        <PageHeader
          style={{ padding: 0, paddingTop: 10 }}
          className="custom-page-header"
          onBack={() => navigate("/app/maintenances")}
          subTitle={
            <div>
              <Typography.Text type="secondary">{t(TRANSLATION_KEY.maintenance)} </Typography.Text>
              <Typography.Text strong>#{maintenanceDetails.order_number}</Typography.Text>
              {maintenanceDetails.work_request && (
                <Popover
                  content={<WorkRequestDetails workRequest={maintenanceDetails.work_request} />}
                >
                  <SolutionOutlined style={{ fontSize: 22, marginLeft: 12, color: "black" }} />
                </Popover>
              )}
            </div>
          }
          extra={[
            hasModul(user.account.company, "electronic_signature") ? (
              <div key="8">
                <DigitalSignatureModal
                  maintenance={maintenanceDetails}
                  digitalSignature={digitalSignature}
                />
              </div>
            ) : null,
            <div key="7">
              <Button
                disabled={maintenanceDetails.id === 0}
                loading={pdfLoading}
                onClick={handleGetPDF}
                icon={<FilePdfOutlined />}
                style={{ marginLeft: 12 }}
              />
            </div>,
            // <div key="6">
            //   <MaintenancePDF
            //     maintenance={maintenanceDetails}
            //     avatar={user.account.company.avatar}
            //     action="open"
            //   />
            // </div>,
            <div key="5">
              {/* Confirm order button */}
              {maintenanceDetails.status === "completed" && (
                <RequirePermission
                  anywayIgnorePermission={maintenanceDetails.reported_by.id === user?.account.id}
                  requiredPermissionCode={["confirm_wo"]}
                  children={
                    <Button onClick={() => set_confirmOrderModalVisible(maintenanceDetails.id)}>
                      {t(TRANSLATION_KEY.closeButton)}
                    </Button>
                  }
                />
              )}
            </div>,
            <div key="4">
              <RequirePermission
                anywayIgnorePermission={hasPermission(user?.account.permissions || [], ["work_wo"])}
                requiredPermissionCode={["manage_wo"]}
                children={
                  <Button onClick={() => set_addWorkingTime(true)} icon={<FieldTimeOutlined />} />
                }
              />
            </div>,
            <div key="3">
              {maintenanceDetails.status !== "cancelled" &&
                maintenanceDetails.status !== "closed" &&
                maintenanceDetails.status !== "completed" && (
                  <RequirePermission
                    requiredPermissionCode={["work_wo"]}
                    children={
                      <StartStop maintenanceDetails={maintenanceDetails} isOpenOrder={false} />
                    }
                  />
                )}
            </div>,
            maintenanceDetails.status !== "cancelled" &&
              maintenanceDetails.status !== "closed" &&
              maintenanceDetails.status !== "completed" && (
                <div key="2">
                  <div className="spaceBetweenRow">
                    {/* <RequirePermission
                    requiredPermissionCode="manage_wo"
                    children={<Open maintenance={maintenanceDetails} />}
                  /> */}
                  </div>
                </div>
              ),
            <div key="1">
              {maintenanceDetails.status !== "cancelled" && (
                <RequirePermission
                  requiredPermissionCode={["manage_wo"]}
                  children={<Reject maintenance={maintenanceDetails} />}
                />
              )}
            </div>,
          ]}
        >
          <Space direction="vertical">
            <div className="spaceBetweenRow">
              <Space size="large" align="center" style={{ height: 44 }}>
                {/* Description */}
                <Typography.Title
                  level={3}
                  style={{ width: 1024 }}
                  ellipsis={{
                    tooltip: maintenanceDetails.description,
                  }}
                  editable={{
                    onChange: (description) => {
                      if (!description) {
                        return;
                      }
                      setMaintenanceDescription(description);
                      updateMaintenance({ description, update_type: "this" });
                    },
                  }}
                >
                  {maintenanceDescription}
                </Typography.Title>
              </Space>

              {/* Timer */}
              {runningTime && <Timer startTime={runningTime} />}

              {/* Held reason */}
              {maintenanceDetails.status === "held" && maintenanceDetails.held_reason && (
                <Tooltip title={maintenanceDetails.held_reason.reason_text}>
                  <Tag
                    className="main-tag"
                    style={{ backgroundColor: "#ddd", color: "#333" }}
                    icon={<PauseCircleTwoTone twoToneColor="#777" style={{ marginRight: 8 }} />}
                  >
                    {t(
                      maintenanceDetails.held_reason.trans_key ||
                        maintenanceDetails.held_reason.name,
                    )}
                  </Tag>
                </Tooltip>
              )}
            </div>
            {/* Informations */}
            <MaintenanceInfo
              isOpenOrder={false}
              grids={LAYOUT_GRIDS}
              user={user}
              maintenanceDetails={maintenanceDetails}
            />
          </Space>
        </PageHeader>

        {/* Tabs */}
        <Tabs id="bordered-tabs" activeKey={location.pathname.split("/")[4]} onChange={onTabChange}>
          {AppRoutes("allroutes")
            .children?.find((x) => x.path === "maintenances/:id")
            ?.children?.filter((x) => {
              //provjeriti ukoliko nije definirana permisija more
              //ukoliko ima permisija viditi dali ima korisnik
              if (x.permissionCode === undefined) {
                return true;
              }
              return user.account.permissions.map((x) => x.codename).includes(x.permissionCode);
            })
            .map((x) => (
              <TabPane
                tab={
                  <Link
                    style={{ color: colors.globalFontColor, textTransform: "capitalize" }}
                    to={x.path}
                  >
                    {t(x.path || "spec")}
                  </Link>
                }
                key={x.path}
              />
            ))}
        </Tabs>
      </div>

      {/* Routes */}
      <Spin spinning={getMaintenanceStatus === "loading"}>
        <Outlet />
      </Spin>

      {/* Working time modal */}
      {maintenanceDetails.id !== 0 && (
        <Modal
          footer={null}
          width={900}
          destroyOnClose={true}
          visible={addWorkingTime}
          onCancel={() => set_addWorkingTime(undefined)}
          title={t(TRANSLATION_KEY.workingTime)}
        >
          <AddWorkingTime
            maintenanceDetails={maintenanceDetails}
            isOpenOrder={false}
            close={() => set_addWorkingTime(undefined)}
          />
        </Modal>
      )}

      {/* Commands modal */}
      <Modal
        footer={null}
        width={540}
        destroyOnClose={true}
        visible={comandsVisible}
        onCancel={() => set_comandsVisible(false)}
        title={t(TRANSLATION_KEY.comands)}
      >
        <Comands onClose={() => set_comandsVisible(false)} />
      </Modal>
      <Modal
        visible={!!confirmOrderModalVisible}
        onCancel={() => {
          set_confirmOrderModalVisible(undefined);
        }}
        footer={null}
        centered
        width="600px"
        destroyOnClose
        title={t(TRANSLATION_KEY.closeMaintenance)}
      >
        {confirmOrderModalVisible && (
          <ConfirmForm
            close={() => {
              set_confirmOrderModalVisible(undefined);
            }}
            selectedMaintenance={confirmOrderModalVisible}
          />
        )}
      </Modal>

      {/* Confirm order modal */}
      <ConfirmOrder
        close={() => set_confirmOrderModalVisible(undefined)}
        order={maintenanceDetails}
      />
    </>
  );
};

export default Maintenance;
