import {
  Checkbox,
  DatePicker,
  Descriptions,
  Input,
  InputNumber,
  message,
  Select,
  Tag,
  Typography,
} from "antd";
import {
  CustomFieldV2,
  CustomFieldValue,
  CustomFieldValues,
  CustomFieldValueType,
} from "../../models/settings";
import moment from "moment";
import { useEffect, useState } from "react";
import { getCustomFieldsV2XHR } from "../../store/reducers/settings/actionCreator";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { FormType } from "../../componentsform/CustomFieldsFormV2";
import { getCustomFieldsByCondition } from "../../componentsform/GenerateForCustomFieldsV2";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../helpers/consts";

interface IProps {
  onChange?: (columns: Record<string, CustomFieldValueType>) => void;
  data: CustomFieldValues | undefined;
  condition?: number;
  formType: FormType;
  alowUpdate?: boolean;
  hide?: boolean;
}

const CustomFieldsSpecGenerator: React.FC<IProps> = ({
  data,
  formType,
  condition,
  onChange,
  alowUpdate,
  hide,
}) => {
  // TREBA OVO UNIVERZALNO

  const dispatch = useAppDispatch();

  const [customFields, setCustomFields] = useState<CustomFieldV2[]>([]);

  const { user } = useAppSelector((state) => state.userReducer);
  const { companyCustomFieldsV2 } = useAppSelector((state) => state.settingsReducer);

  useEffect(() => {
    setCustomFields(companyCustomFieldsV2[formType]);
  }, [companyCustomFieldsV2]);

  const _onChange = (value: string | string[] | boolean | number | null, cf_key: string) => {
    if (onChange) {
      let tmp = getCustomFieldsByCondition(customFields, condition);

      let val: any = {};
      tmp.forEach((x) => {
        val[x.cf_id] = x.cf_id === cf_key ? value : data?.[x.cf_id]?.value;
      });
      onChange(val);
    }
  };

  let generate = (
    data: CustomFieldValue | undefined,
    label: string,
    dynamicCustomField: CustomFieldV2,
  ) => {
    if (dynamicCustomField?.type === "datetime") {
      return (
        <Descriptions.Item className="dateDynamicColumns" label={label}>
          <DatePicker
            bordered={false}
            disabled={!alowUpdate}
            showTime
            style={{
              minWidth: 200,
              maxWidth: 270,
              color: "black !important",
            }}
            value={data?.value ? moment(data.value as string) : undefined}
            format={user.account?.date_format || "DD.MM.YYYY - HH:mm"}
            onChange={(val) => {
              if (!val) return;
              if (val.toISOString() === data?.value) return;
              _onChange(val.toISOString(), dynamicCustomField.cf_id);
            }}
          />
        </Descriptions.Item>
      );
    }
    if (dynamicCustomField?.type === "flag") {
      let val = !!data?.value as boolean;
      return (
        <Descriptions.Item label={label}>
          <Checkbox
            disabled={!alowUpdate}
            checked={!!val}
            onChange={() => {
              _onChange(!val, dynamicCustomField.cf_id);
            }}
          />
        </Descriptions.Item>
      );
    }

    if (dynamicCustomField?.type === "select") {
      let arr = data?.value as string[];
      return (
        <Descriptions.Item label={label}>
          <Select
            bordered={false}
            style={{
              minWidth: 200,
              maxWidth: 270,
              color: "black",
            }}
            disabled={!alowUpdate}
            mode="multiple"
            maxTagCount={3}
            value={arr || []}
            onChange={(val) => {
              if (val === data?.value) return;

              _onChange(val, dynamicCustomField.cf_id);
            }}
          >
            {dynamicCustomField?.data?.map((x, index) => (
              <Select.Option key={index} value={x}>
                {x}
              </Select.Option>
            ))}
          </Select>
        </Descriptions.Item>
      );
    }
    if (dynamicCustomField?.type === "number") {
      return (
        <Descriptions.Item label={label}>
          <InputNumber
            bordered={false}
            style={{
              minWidth: 200,
              maxWidth: 270,
              color: "black",
            }}
            disabled={!alowUpdate}
            value={
              data?.value === undefined || data.value === null ? undefined : Number(data.value)
            }
            onBlur={(e) => {
              if (Number(e.target.value) === data?.value) return;
              console.log(e.target.value);
              if (isNaN(Number(e.target.value))) {
                message.error(t(TRANSLATION_KEY.badInput));
                return;
              }
              _onChange(e.target.value ? Number(e.target.value) : null, dynamicCustomField.cf_id);
            }}
          />
        </Descriptions.Item>
      );
    }

    if (dynamicCustomField?.type === "text") {
      return (
        <Descriptions.Item label={label}>
          <Input
            key={data?.value?.toString()}
            bordered={false}
            style={{
              minWidth: 200,
              maxWidth: 270,
              color: "black",
            }}
            disabled={!alowUpdate}
            defaultValue={data?.value?.toString() || ""}
            onBlur={(val) => {
              if (val.target.value === data?.value) return;
              _onChange(val.target.value, dynamicCustomField.cf_id);
            }}
          />
        </Descriptions.Item>
      );
    }

    return (
      <Descriptions.Item label={label}>
        <Typography.Text style={{ color: "black" }}>-</Typography.Text>
      </Descriptions.Item>
    );
  };

  if (hide) return null;

  return (
    <>
      {getCustomFieldsByCondition(customFields, condition)?.map((x, index) => {
        let field = data?.[x.cf_id];
        return generate(field, x.name, x);
      })}
    </>
  );
};

export default CustomFieldsSpecGenerator;
