import { Button, DatePicker, Space } from "antd";
import React from "react";
import moment from "moment";
import DatePickerCustom, { CalendarTypes } from "../DatePickerCustom";

type DateFilterValue = {
  from: string | undefined;
  to: string | undefined;
};

interface IProps {
  column_key: string;
  defaultDateValue: DateFilterValue | undefined;
  close?: () => void;
  onChange: (value: DateFilterValue, column_key: string) => void;
}

const DateTimeFilter: React.FC<IProps> = ({ defaultDateValue, close, onChange, column_key }) => {
  const [pickerType, set_pickerType] = React.useState<CalendarTypes>("month");
  console.log(defaultDateValue, "DEFAULT VALUE");
  return (
    <div style={{ padding: 6 }}>
      {" "}
      <DatePickerCustom
        start={defaultDateValue?.from}
        end={defaultDateValue?.to}
        pickerStyle={{ width: pickerType === "date" ? 330 : undefined }}
        skipInitial={true}
        type={pickerType}
        onChange={function (
          start: string | undefined,
          end: string | undefined,
          type?: CalendarTypes | undefined,
        ): void {
          if (type === "date") {
            set_pickerType("date");
            onChange({ from: start, to: end }, column_key);
          } else {
            set_pickerType(type || "month");
            onChange(
              {
                from: moment(start)
                  .startOf(type || "month")
                  .toISOString(true),
                to: moment(end)
                  .endOf(type || "month")
                  .toISOString(true),
              },
              column_key,
            );
          }
        }}
      />
    </div>
  );
};

export default DateTimeFilter;
