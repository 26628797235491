import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse, ILocation, IUsualExpense } from "../../../models";
import {
  CompanyCustomFieldsV2,
  CustomFieldV2,
  IChecklist,
  ICompanyCustomFields,
  ICompanySettings,
  ICurrency,
  IPermission,
  IRequestPanel,
  IRequestPanelDetails,
  IRole,
  ITask,
} from "../../../models/settings";
import { Languages } from "../../../helpers/consts";
import { FormType } from "../../../componentsform/CustomFieldsFormV2";

interface ISettingsReducer {
  updateCustomFieldStatus: string;
  getCustomFieldsV2Status: string;
  createCustomFieldV2Status: string;
  companyCustomFieldsV2: CompanyCustomFieldsV2;
  requestPanelDetails: IRequestPanelDetails | undefined;
  getRequestPanelDetailsStatus: string;
  updateRequestPanelStatus: string;
  getRequestPanelStatus: string;
  requestPanels: IRequestPanel[];
  createRequestPanelStatus: string;
  getCompanyCusstomFieldsStatus: string;
  locations: ILocation[];
  getLocationsStatus: string;
  usualExpenses: IUsualExpense[];
  getUsualExpensesStatus: string;
  getCompanySettingsStatus: string;
  updateCompanySettingsStatus: string;
  companySettings: ICompanySettings;
  companyCustomFields: ICompanyCustomFields | undefined;
  getCompanyCustomFieldsStatus: string;
  getRolesStatus: string;
  roles: IRole[];
  getPermisionsStatus: string;
  permissions: IPermission[];
  createRoleStatus: string;
  updateRoleStatus: string;
  checklists: IChecklist[];
  getChecklistsStatus: string;
  setTaskRankStatus: string;
  currencies: string[];
  getCurrenciesStatus: string;
}

const initialState = {
  updateCustomFieldStatus: "",
  getCustomFieldsV2Status: "loading",
  companyCustomFieldsV2: {
    account: [],
    asset: [],
    clients: [],
    entry: [],
    items: [],
    orderconfirm: [],
    spend: [],
    suppliers: [],
    workorder: [],
    rent_order: [],
  },
  createCustomFieldV2Status: "",
  requestPanelDetails: undefined,
  getRequestPanelDetailsStatus: "",
  updateRequestPanelStatus: "",
  getRequestPanelStatus: "",
  requestPanels: [],
  createRequestPanelStatus: "",
  getCompanyCusstomFieldsStatus: "",
  locations: [],
  getLocationsStatus: "",
  usualExpenses: [],
  getUsualExpensesStatus: "",
  getCompanySettingsStatus: "",
  updateCompanySettingsStatus: "",
  companySettings: {
    asset_custom_fields: [],
    global_hour_price: "",
    currency: "",
    delayed_order_after_planned_time: "",
    show_preventive_maintenance_before: "",
    order_partner_custom_fields: [],
    order_custom_fields: [],
    part_custom_fields: [],
    supplier_custom_fields: [],
    report_required: false,
    language: Languages.English,
  },
  getRolesStatus: "",
  roles: [],
  getPermisionsStatus: "",
  permissions: [],
  createRoleStatus: "",
  updateRoleStatus: "",
  checklists: [],
  getChecklistsStatus: "",
  setTaskRankStatus: "",
  companyCustomFields: {
    asset_custom_fields: [],
    order_partner_custom_fields: [],
    order_custom_fields: [],
    part_custom_fields: [],
    supplier_custom_fields: [],
  },
  getCompanyCustomFieldsStatus: "",
  currencies: [],
  getCurrenciesStatus: "",
} as ISettingsReducer;

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    // -----

    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    getLocations(state) {
      state.getLocationsStatus = "loading";
    },
    getLocationsSuccess(state, action: PayloadAction<IApiResponse<ILocation[]>>) {
      if (action.payload.results) {
        state.locations = action.payload.results;
      }
      state.getLocationsStatus = "";
    },
    getLocationsFail(state, action: PayloadAction<string>) {
      state.getLocationsStatus = action.payload;
    },

    // -----

    getUsualExpenses(state) {
      state.getUsualExpensesStatus = "loading";
    },
    getUsualExpensesSuccess(state, action: PayloadAction<IApiResponse<IUsualExpense[]>>) {
      if (action.payload.results) {
        state.usualExpenses = action.payload.results;
      }
      state.getUsualExpensesStatus = "";
    },
    getUsualExpensesFail(state, action: PayloadAction<string>) {
      state.getUsualExpensesStatus = action.payload;
    },

    // -----

    getCompanySettings(state) {
      state.getCompanySettingsStatus = "loading";
    },
    getCompanySettingsSuccess(state, action: PayloadAction<IApiResponse<ICompanySettings>>) {
      if (action.payload.results) {
        state.companySettings = action.payload.results;
      }
      state.getCompanySettingsStatus = "";
    },
    getCompanySettingsFail(state, action: PayloadAction<string>) {
      state.getCompanySettingsStatus = action.payload;
    },

    // -----

    getCompanyCustomFields(state) {
      state.getCompanyCustomFieldsStatus = "loading";
    },
    getCompanyCustomFieldsSuccess(
      state,
      action: PayloadAction<IApiResponse<ICompanyCustomFields>>,
    ) {
      if (action.payload.results) {
        state.companyCustomFields = action.payload.results;
      }
      state.getCompanyCustomFieldsStatus = "";
    },
    getCompanyCustomFieldsFail(state, action: PayloadAction<string>) {
      state.getCompanyCustomFieldsStatus = action.payload;
    },

    // -----

    updateCompanySettings(state) {
      state.updateCompanySettingsStatus = "loading";
    },
    updateCompanySettingsSuccess(state, action: PayloadAction<IApiResponse<ICompanyCustomFields>>) {
      if (action.payload.results) {
        state.companyCustomFields = action.payload.results;
      }
      state.updateCompanySettingsStatus = "";
    },
    updateCompanySettingsFail(state, action: PayloadAction<string>) {
      state.updateCompanySettingsStatus = "";
    },

    // -----

    getRoles(state) {
      state.getRolesStatus = "loading";
    },
    getRolesSuccess(state, action: PayloadAction<IApiResponse<IRole[]>>) {
      if (action.payload.results) {
        state.roles = action.payload.results;
      }
      state.getRolesStatus = "";
    },
    getRolesFail(state, action: PayloadAction<string>) {
      state.getRolesStatus = action.payload;
    },

    // -----

    getPermisions(state) {
      state.getPermisionsStatus = "loading";
    },
    getPermisionsSuccess(state, action: PayloadAction<IApiResponse<IPermission[]>>) {
      if (action.payload.results) {
        state.permissions = action.payload.results;
      }
      state.getPermisionsStatus = "";
    },
    getPermisionsFail(state, action: PayloadAction<string>) {
      state.getPermisionsStatus = action.payload;
    },

    // -----

    createRole(state) {
      state.createRoleStatus = "loading";
    },
    createRoleSuccess(state, action: PayloadAction<IApiResponse<IRole[]>>) {
      if (action.payload.results) {
        state.roles = action.payload.results;
      }
      state.createRoleStatus = "";
    },
    createRoleFail(state, action: PayloadAction<string>) {
      state.createRoleStatus = action.payload;
    },

    // -----

    updateRole(state) {
      state.updateRoleStatus = "loading";
    },
    updateRoleSuccess(state, action: PayloadAction<IApiResponse<IRole[]>>) {
      if (action.payload.results) {
        state.roles = action.payload.results;
      }
      state.updateRoleStatus = "";
    },
    updateRoleFail(state, action: PayloadAction<string>) {
      state.updateRoleStatus = action.payload;
    },

    // -----

    getChecklists(state) {
      state.getChecklistsStatus = "loading";
    },
    getChecklistsSuccess(state, action: PayloadAction<IApiResponse<IChecklist[]>>) {
      if (action.payload.results) {
        state.checklists = action.payload.results;
      }
      state.getChecklistsStatus = "";
    },
    getChecklistsFail(state, action: PayloadAction<string>) {
      state.getChecklistsStatus = action.payload;
    },

    // -----

    setTaskRank(state) {
      state.setTaskRankStatus = "loading";
    },
    setTaskRankSuccess(state, action: PayloadAction<IApiResponse<ITask[]>>) {
      state.setTaskRankStatus = "";
    },
    setTaskRankFail(state, action: PayloadAction<string>) {
      state.setTaskRankStatus = action.payload;
    },

    getCompanyCusstomFields(state) {
      state.getCompanyCusstomFieldsStatus = "loading";
    },
    getCompanyCusstomFieldsSuccess(
      state,
      action: PayloadAction<IApiResponse<ICompanyCustomFields>>,
    ) {
      state.companyCustomFields = action.payload.results;
      state.getCompanyCusstomFieldsStatus = "";
    },
    getCompanyCusstomFieldsFail(state, action: PayloadAction<string>) {
      state.getCompanyCusstomFieldsStatus = action.payload;
    },

    // -----

    getCurrencies(state) {
      state.getCurrenciesStatus = "loading";
    },
    getCurrenciesSuccess(state, action: PayloadAction<IApiResponse<string[]>>) {
      if (action.payload.results) {
        console.log(action.payload.results);
        state.currencies = action.payload.results;
      }
      state.getCurrenciesStatus = "";
    },
    getCurrenciesFail(state, action: PayloadAction<string>) {
      state.getCurrenciesStatus = "error";
    },

    createRequestPanel(state) {
      state.createRequestPanelStatus = "loading";
    },
    createRequestPanelSuccess(state, action: PayloadAction<IApiResponse<IRequestPanel>>) {
      let tmp = [...state.requestPanels];
      if (action.payload.results) {
        tmp.push(action.payload.results);
      }
      state.requestPanels = tmp;
      state.createRequestPanelStatus = "";
    },
    createRequestPanelFail(state, action: PayloadAction<string>) {
      state.createRequestPanelStatus = action.payload;
    },

    // -----

    getRequestPanel(state) {
      state.getRequestPanelStatus = "loading";
    },
    getRequestPanelSuccess(state, action: PayloadAction<IApiResponse<IRequestPanel[]>>) {
      if (action.payload.results) state.requestPanels = action.payload.results;
      state.getRequestPanelStatus = "";
    },
    getRequestPanelFail(state, action: PayloadAction<string>) {
      state.getRequestPanelStatus = action.payload;
    },

    // -----

    updateRequestPanel(state) {
      state.updateRequestPanelStatus = "loading";
    },
    updateRequestPanelSuccess(state, action: PayloadAction<IApiResponse<IRequestPanel>>) {
      let tmp = [...state.requestPanels];
      if (action.payload.results) {
        let index = tmp.findIndex((item) => item.id === action.payload.results?.id);
        tmp[index] = action.payload.results;
      }
      state.requestPanels = tmp;
      state.updateRequestPanelStatus = "";
    },
    updateRequestPanelFail(state, action: PayloadAction<string>) {
      state.updateRequestPanelStatus = action.payload;
    },

    getRequestPanelDetails(state) {
      state.getRequestPanelDetailsStatus = "loading";
    },
    getRequestPanelDetailsSuccess(
      state,
      action: PayloadAction<IApiResponse<IRequestPanelDetails>>,
    ) {
      state.requestPanelDetails = action.payload.results;
      state.getRequestPanelDetailsStatus = "";
    },
    getRequestPanelDetailsFail(state, action: PayloadAction<string>) {
      state.getRequestPanelDetailsStatus = action.payload;
    },

    // -----

    createCustomFieldV2(state) {
      state.createCustomFieldV2Status = "loading";
    },
    createCustomFieldV2Success(state, action: PayloadAction<IApiResponse<CompanyCustomFieldsV2>>) {
      console.log(action.payload.results, "GHJKGHJK");
      if (action.payload.results) {
        state.companyCustomFieldsV2 = action.payload.results;
      }
      state.createCustomFieldV2Status = "";
    },
    createCustomFieldV2Fail(state, action: PayloadAction<string>) {
      state.createCustomFieldV2Status = action.payload;
    },

    // -----

    getCustomFieldsV2(state) {
      state.getCustomFieldsV2Status = "loading";
    },
    getCustomFieldsV2Success(state, action: PayloadAction<IApiResponse<CompanyCustomFieldsV2>>) {
      if (action.payload.results) {
        state.companyCustomFieldsV2 = action.payload.results;
      }
      state.getCustomFieldsV2Status = "";
    },
    getCustomFieldsV2Fail(state, action: PayloadAction<string>) {
      state.getCustomFieldsV2Status = action.payload;
    },

    updateCustomField(state) {
      state.updateCustomFieldStatus = "loading";
    },
    updateCustomFieldSuccess(state, action: PayloadAction<IApiResponse<CompanyCustomFieldsV2>>) {
      if (action.payload.results) {
        state.companyCustomFieldsV2 = action.payload.results;
      }
      state.updateCustomFieldStatus = "";
    },
    updateCustomFieldFail(state, action: PayloadAction<string>) {
      state.updateCustomFieldStatus = action.payload;
    },

    stopLoading(state) {
      state.getCustomFieldsV2Status = "";
    },
  },
});

export default settingsSlice.reducer;
