import { Col, Divider, message, Row, Spin, Tabs, Typography } from "antd";
import { t } from "i18next";
import { useEffect } from "react";
import { MODUL_CLIENTS, TRANSLATION_KEY } from "../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import CheckList from "../CheckList";
import RequestPanels from "../RequestPanels";
import UsualExpenses from "../Tags/pages/Expenses";
import Locations from "../Tags/pages/Locations";
import Asset from "./Asset";
import Maintenance from "./Maintenance";
import Supplier from "./Supplier";
import HumanResources from "./HumanResources";
import Parts from "./Parts";
import { hasModul } from "../../../../helpers/functions";
import Clients from "./Clients";

const { TabPane } = Tabs;

const Modeling: React.FC = () => {
  const dispatch = useAppDispatch();
  const { getCustomFieldsV2Status } = useAppSelector((state) => state.settingsReducer);
  const {
    user: {
      account: { company },
    },
  } = useAppSelector((state) => state.userReducer);

  return (
    <>
      <Row gutter={[24, 12]}>
        {/* Modeling */}
        <Col span={24}>
          <Spin size="small" spinning={getCustomFieldsV2Status === "loading"}>
            <div className="white-container" style={{ paddingBottom: 20 }}>
              <Typography.Title level={5} style={{ paddingTop: 12 }}>
                {t(TRANSLATION_KEY.dynamicFields)}
              </Typography.Title>
              <Divider style={{ marginBottom: 10 }} />
              <Tabs size="small" tabPosition="left" defaultActiveKey="asset">
                <TabPane tab={t(TRANSLATION_KEY.asset)} key="asset">
                  <Asset />
                </TabPane>
                <TabPane tab={t(TRANSLATION_KEY.maintenance)} key="maintenance">
                  <Maintenance />
                </TabPane>
                <TabPane tab={t(TRANSLATION_KEY.part)} key="parts">
                  <Parts />
                </TabPane>
                <TabPane tab={t(TRANSLATION_KEY.humanResources)} key="humanresources">
                  <HumanResources />
                </TabPane>
                <TabPane tab={t(TRANSLATION_KEY.suppliers)} key="supplier">
                  <Supplier />
                </TabPane>
                {hasModul(company, MODUL_CLIENTS) && (
                  <TabPane tab={t(TRANSLATION_KEY.clients)} key="clients">
                    <Clients />
                  </TabPane>
                )}
              </Tabs>
            </div>
          </Spin>
        </Col>

        {/* Locations */}
        <Col span={24}>
          <div className="white-container">
            <Locations />
          </div>
        </Col>

        {/* Usual Expenses */}
        <Col span={24}>
          <div className="white-container">
            <UsualExpenses />
          </div>
        </Col>

        {/* Check List */}
        <Col span={24}>
          <div className="white-container">
            <CheckList />
          </div>
        </Col>

        {/* Request panel  */}
        <Col span={24}>
          <div className="white-container">
            <RequestPanels />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Modeling;
