import {
  Alert,
  Button,
  Drawer,
  Input,
  message,
  PageHeader,
  Table,
  TableColumnType,
  Tooltip,
  Typography,
} from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ILoadMoreParams } from "../../models";
import { DynamicFiltersType, IAccount, TableView } from "../../models/user";
import { Link } from "react-router-dom";
import { accountsXHR } from "../../store/reducers/accounts/actionCreators";
import AccountForm from "../../componentsform/AccountForm";
import moment from "moment";
import { InfoCircleOutlined } from "@ant-design/icons";
import { TRANSLATION_KEY } from "../../helpers/consts";
import LoadMoreButton from "../../components/LoadMoreButton";
import ViewTabs, {
  ColumnFilterType,
  CustomTableColumn,
  customTableColumnRender,
  filterColumns,
  getFilterComponent,
  saveTableDefinition,
} from "../../components/ViewTabs";
import Columns from "../../components/ViewTabs/Columns";
import { getFiltersForQuery } from "../../helpers/functions";
const ACCOUNT_LIMIT = 30;

type TableRenders =
  | "AccountName"
  | "AccountCreatedAt"
  | "AccountRole"
  | "AccountNumber"
  | "AccountEmail";

const HumanResources: React.FC = () => {
  const dispatch = useAppDispatch();

  const { accountsStatus, accounts } = useAppSelector((state) => state.accountsReducer);
  const { user, createViewStatus, updateViewStatus } = useAppSelector((state) => state.userReducer);
  const [addAccountVisible, set_addAccountVisible] = useState<boolean>(false);
  const [loadMoreVisible, set_loadMoreVisible] = useState<boolean>(true);
  const [firstMount, set_firstMount] = useState<boolean>(true);
  const [tableDefinition, set_tableDefinition] = useState<
    Array<
      TableView & {
        updated: number;
        active: boolean;
      }
    >
  >(
    user.account.views?.account?.map((x, i) => ({
      ...x,
      updated: new Date().getTime(),
      active: i === 0,
    })) || [],
  );
  const [search, set_search] = useState<string>("");
  const [localQueryParams, set_localQueryParams] = useState<ILoadMoreParams>({
    // da bi pravilno radio load more sa filterima bitno je ne updateati pocetno stane iz reduxa
    offset: 0,
    limit: ACCOUNT_LIMIT,
  });

  const render = (key: TableRenders) => {
    let tmp: Record<TableRenders, any> = {
      AccountName: (text: string, value: IAccount) => <Link to={`${value.id}`}>{value.name}</Link>,
      AccountRole: (text: string, value: IAccount) => <div>{t(value.role.name)}</div>,
      AccountCreatedAt: (text: string, value: IAccount) =>
        moment(text).format(user.account.date_format || "DD.MM.YYYY HH:mm"),
      AccountNumber: (text: string, value: IAccount) => (
        <Typography.Text>{value.phone_number}</Typography.Text>
      ),
      AccountEmail: (text: string, value: IAccount) => (
        <Typography.Text>{value.email}</Typography.Text>
      ),
    };
    return tmp[key];
  };

  const [COLUMNS, set_COLUMNS] = React.useState<
    Array<Partial<CustomTableColumn> & TableColumnType<IAccount>>
  >([]);

  useEffect(() => {
    if (firstMount) {
      set_firstMount(false);
      return;
    }
    let tmpDefinitions =
      user.account.views?.account?.map((x, i) => ({
        ...x,
        updated: new Date().getTime(),
        active: i === 0,
      })) || [];
    set_tableDefinition(tmpDefinitions);
  }, [user]);

  const getAccounts = (
    queryParams: ILoadMoreParams,
    filters: DynamicFiltersType | undefined,
    activeIndex: number | undefined,
    updated: number | undefined,
    mergeData: boolean,
  ) => {
    // if (accounts.cursor.next === null) {
    //   return;
    // }
    let filtersParesForQuery = getFiltersForQuery(filters);

    if (queryParams.offset === 0) {
      set_loadMoreVisible(true);
    }

    accountsXHR(
      {
        mergeData: mergeData,
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        queryParams: {
          ...queryParams,
          ...filtersParesForQuery,
          order_by: "id",
        },

        successCallback: (res) => {
          if (res.results) {
            if (res.results.length < queryParams.limit) {
              set_loadMoreVisible(false);
            }
            set_localQueryParams({
              ...queryParams,
              offset: queryParams.offset + res.results.length,
            });
            let tmp = [...tableDefinition];

            let tmpIndex =
              activeIndex !== undefined
                ? activeIndex
                : tableDefinition.findIndex((view) => view.active === true);

            tmp = tmp.map((x) => ({ ...x, active: false }));
            tmp[tmpIndex] = {
              ...tmp[tmpIndex],
              updated: updated ? updated : tmp[tmpIndex].updated,
              active: true,
              filters: filters,
            };
            set_tableDefinition(tmp);
          }
        },
      },
      dispatch,
    );
  };

  useEffect(() => {
    let activeView = tableDefinition.find((x) => x.active);
    if (activeView) {
      setColumns(activeView.table_structure, 0);
    }
    getAccounts(
      localQueryParams,
      tableDefinition[0].filters,
      undefined,
      tableDefinition[0].updated,
      false,
    );
  }, []);

  const setColumns = (_c: CustomTableColumn[], viewIndex: number) => {
    // ostaje unutar komponente za koju se prave views-ovi iz razloga jer će trebati filtere okidati
    let tmp: Array<CustomTableColumn & TableColumnType<IAccount>> = [];
    _c.forEach((c) => {
      tmp.push({
        ...c,
        title: t(c.title),
        render: c.columnRenderComponent
          ? render(c.columnRenderComponent as TableRenders)
          : customTableColumnRender(
              c.filterComponent as ColumnFilterType,
              c.dataIndex,
              user,
              "custom_fields_v2",
            ),
        onCell: (record: IAccount) => {
          return { rowSpan: 1 };
        },
        filterDropdown: undefined,
      });
    });
    set_COLUMNS(tmp);
  };

  return (
    <div>
      {/* Page header */}
      <PageHeader
        style={{ padding: 0, paddingTop: 10, marginBottom: 2 }}
        title={t(TRANSLATION_KEY.humanResources)}
        extra={[
          <Button onClick={() => set_addAccountVisible(true)} type="primary">
            {t(TRANSLATION_KEY.add)}
          </Button>,
        ]}
      />
      <ViewTabs
        onChange={(activeKey) => {
          let index = tableDefinition.findIndex((view) => view.name === activeKey);
          let tmpList = [...tableDefinition];
          if (index === -1) {
            message.error("view_onSaveColumnsDefinition");
            return;
          }
          tmpList = tmpList.map((x) => ({ ...x, active: false }));
          tmpList[index] = { ...tmpList[index], active: true };
          getAccounts(
            { ...localQueryParams, offset: 0 },
            tmpList[index].filters,
            index,
            tmpList[index].updated,
            false,
          );
          setColumns(tmpList[index].table_structure, index);
          set_tableDefinition(tmpList);
        }}
        setColumns={setColumns}
        views={tableDefinition}
        viewCategory="account"
      />

      {/* Table header */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "18px 0",
        }}
      >
        <div>
          <Columns
            columns={COLUMNS}
            set_COLUMNS={(columns) => {
              let index = tableDefinition.findIndex((view) => view.active === true);
              if (index === -1) {
                message.error("view");
                return;
              }
              let tmp = tableDefinition[index];
              if (!tmp) {
                return;
              }

              let inerTmp: CustomTableColumn[] = [];
              columns.forEach((x) => {
                inerTmp.push({
                  title: x.title || "",
                  dataIndex: x.dataIndex || "",
                  visible: x.visible === undefined ? 1 : x.visible,
                  onCellFlag: x.onCellFlag || false,
                  filterComponent: x.filterComponent || null,
                  columnRenderComponent: x.columnRenderComponent || null,
                  objectKey: x.objectKey || null,
                });
              });
              tmp = { ...tmp, table_structure: inerTmp, updated: Date.now(), active: true };
              let tmpTableDefinition = [...tableDefinition].map((x) => ({ ...x, active: false }));
              tmpTableDefinition[index] = tmp;
              setColumns(inerTmp, index);
              set_tableDefinition(tmpTableDefinition);
            }}
          />
          <Button
            style={{ marginLeft: 18 }}
            type="link"
            onClick={() => {
              let activeView = tableDefinition.find((x) => x.active);
              saveTableDefinition(
                tableDefinition,
                user.account.views?.account?.find((x) => x.id === activeView?.id),
                "account",
              );
            }}
          >
            {t(TRANSLATION_KEY.saveChanges)}
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip title={t(TRANSLATION_KEY.searchGouesByNameEmailRole)}>
              <InfoCircleOutlined />
            </Tooltip>
            <div style={{ width: 12 }} />

            <Input.Search
              value={search || ""} // (tableDefinition.find((x) => x.active)?.filters?.search as string) bio je neki bug kad dođu viewovi postavit da radi
              onChange={({ target: { value } }) => {
                let v = value?.toLowerCase() || "";
                set_search(v);
                let filters = tableDefinition.find((x) => x.active)?.filters || {};
                getAccounts(
                  { ...localQueryParams, offset: 0 },
                  { ...filters, search: v },
                  undefined,
                  Date.now(),
                  false,
                );
              }}
              allowClear
            />
          </div>
        </div>
      </div>

      <Table
        loading={
          accountsStatus === "loading" ||
          createViewStatus === "loading" ||
          updateViewStatus === "loading"
        }
        size="small"
        rowKey={(item) => item.id}
        dataSource={accounts}
        columns={filterColumns<IAccount>(COLUMNS)}
        pagination={false}
      />
      {loadMoreVisible && (
        <LoadMoreButton
          loading={false}
          onClick={() => {
            let filters = tableDefinition.find((x) => x.active)?.filters || {};
            getAccounts(localQueryParams, filters, undefined, undefined, true);
          }}
        />
      )}

      <Drawer
        title={t(TRANSLATION_KEY.addUser)}
        width={540}
        destroyOnClose={true}
        children={<AccountForm account={null} close={() => set_addAccountVisible(false)} />}
        visible={addAccountVisible}
        onClose={() => set_addAccountVisible(false)}
      />
    </div>
  );
};

export default HumanResources;
