import React, { useState, useEffect, useRef } from "react";
import { IMaintenance } from "../../../models/maintenances";
import { useAppSelector } from "../../../hooks";
import { maintenanceSlice } from "../../../store/reducers/maintenance";

// UI
import { Drawer, message, Spin } from "antd";
import styles from "./styles.module.css";
import { motion } from "framer-motion";

// Helpers
import { parseDate, parseStartDate, parseEndDate } from "./helpers";
import { filter, hasPermission } from "../../../helpers/functions";

// Calendar
import FullCalendar, {
  DatesSetArg,
  DateSelectArg,
  EventInput,
  EventDropArg,
  EventClickArg,
  LocaleInput,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import hr from "@fullcalendar/core/locales/hr";
import en from "@fullcalendar/core/locales/en-gb";
import de from "@fullcalendar/core/locales/de";
import rs from "@fullcalendar/core/locales/sr";
import interactionPlugin, { DropArg, EventResizeDoneArg } from "@fullcalendar/interaction";
import moment from "moment";

// Config
import {
  getCalendarMaintenancesXHR,
  getDroppableCalendarMaintenancesXHR,
  updateMaintenanceXHR,
} from "../../../store/reducers/maintenance/actionCreator";
import { useDispatch } from "react-redux";

// Components
import MaintenanceForm, { IMaintenanceForm } from "../../../componentsform/MaintenanceForm";
import RenderEvent from "./components/RenderEvent";
import Filters from "./components/Filters";
import DropEvents from "./components/DropEvents";

// Translation
import { t } from "i18next";
import { Languages, TRANSLATION_KEY } from "../../../helpers/consts";
import { dashboardSlice } from "../../../store/reducers/dashboard";

export interface IFilters {
  assets: number[];
  types: number[];
  executors: number[];
  locations: number[];
  categories: number[];
  statuses?: string[];
}

interface TimeNeeded {
  hh: number;
  mm: number;
}

const initialFilters: IFilters = {
  assets: [],
  types: [],
  executors: [],
  locations: [],
  categories: [],
};

interface IProps {
  asset?: number;
  executor_accounts?: number[];
  executor_suppliers?: number[];
  fromModal?: boolean;
}

const CalendarMaintenances: React.FC<IProps> = ({
  asset,
  executor_accounts,
  executor_suppliers,
  fromModal,
}) => {
  // Hooks
  const dispatch = useDispatch();
  const calendarRef = useRef<FullCalendar>(null!);

  // State
  const {
    calendarMaintenances,
    getCalendarMaintenancesStatus,
    droppableCalendarMaintenances,
    getDroppableCalendarMaintenancesStatus,
    getMaintenanceListStatus,
    updateMaintenanceStatus,
    liveMaintenances,
  } = useAppSelector((state) => state.maintenanceReducer);
  const { user } = useAppSelector((state) => state.userReducer);

  // Variables
  const [addMaintenance, set_addMaintenance] = useState<IMaintenanceForm>();
  const [loading, set_loading] = useState<boolean>(false);
  const [initialLoading, set_initialLoading] = useState<boolean>(true);
  const [filters, set_filters] = useState<IFilters>(initialFilters);
  const [dropEvents, set_dropEvents] = useState<IMaintenance[]>(droppableCalendarMaintenances);
  const [dropEventsVisible, set_dropEventsVisible] = useState<boolean>(false);
  const [aspectRatio, set_aspectRatio] = useState<number>(2.8);
  const [timeNeeded, set_timeNeeded] = useState<TimeNeeded>({ hh: 0, mm: 0 });

  // Methods
  useEffect(() => {
    const planned_start_from = parseStartDate(moment().startOf("month"));
    const planned_start_to = parseEndDate(moment().endOf("month"));
    fetchMaintenances(planned_start_from, planned_start_to);
    getDroppableCalendarMaintenancesXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback(data) {
          if (data.results) {
            set_dropEvents(data.results);
          }
        },
      },
      dispatch,
    );
    setTimeout(() => {
      set_initialLoading(false);
    }, 500);
  }, []);

  //? Fetching maintenances based on from/to planned start time
  function fetchMaintenances(planned_start_from: string, planned_start_to: string): void {
    getCalendarMaintenancesXHR(
      {
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        queryParams: {
          planned_start_from,
          planned_start_to,
          asset: asset || null,
          executor_accounts: executor_accounts?.join("|") || null,
          executor_suppliers: executor_suppliers?.join("|") || null,
          next: null,
          order_by: "id",
          ordering: "desc",
          limit: 1000,
          cursor: "id",
        },
      },
      dispatch,
    );
  }

  //* Dropping maintenances
  //*
  //? Changing view to 'weeks view' when 'Add Maintenances' modal is visible
  //? Cannot add maintenances from 'months view' - unable to select hours
  useEffect(() => {
    let calendarApi = calendarRef?.current?.getApi();
    if (dropEventsVisible) {
      if (calendarApi?.view?.type !== "timeGridWeek" && calendarApi?.view?.type !== "timeGridDay") {
        // calendarApi?.changeView('timeGridWeek')
      }
    }
    // Wait for animations to end
    setTimeout(() => calendarApi?.updateSize(), 750);
  }, [dropEventsVisible]);

  //? If current view is 'months view' - close 'Add Maintenances' modal
  useEffect(() => {
    if (calendarRef?.current?.getApi()?.view?.type === "dayGridMonth") {
      set_dropEventsVisible(false);
    }
    // Wait for animations to end
    setTimeout(() => calendarRef?.current?.getApi()?.updateSize(), 750);
  }, [calendarRef?.current?.getApi()?.view]);
  //*
  //* Dropping maintenances

  //? Changing aspect ratio on dropEventsVisible change
  useEffect(() => {
    if (dropEventsVisible) set_aspectRatio(2);
    else set_aspectRatio(2.8);
  }, [dropEventsVisible]);

  useEffect(() => {
    if (!!addMaintenance) {
      set_dropEventsVisible(false);
    }
  }, [addMaintenance]);

  function onCalendarLoad(isLoading: boolean) {
    set_loading(isLoading);
  }

  async function handleMonthChange(payload: DatesSetArg) {
    const planned_start_from = parseStartDate(payload.startStr);
    const planned_start_to = parseEndDate(payload.endStr);
    fetchMaintenances(planned_start_from, planned_start_to);
  }

  function toggleDrawer(payload: DateSelectArg) {
    //* Creating clicked date
    let clickedDate = moment(payload.startStr).format("YYYY-MM-DD HH:mm");

    //* Creating time_needed
    const end = moment(payload.endStr);
    const start = moment(payload.startStr);

    // Calculate the duration between end and start
    const duration = moment.duration(end.diff(start));

    // Get the hours and minutes from the duration
    const hours = duration.hours();
    const minutes = duration.minutes();

    // Setting timeNeeded
    set_timeNeeded({ hh: hours, mm: minutes });

    if (payload)
      set_addMaintenance({
        workorder_custom_fields_v2: {},
        orderconfirm_custom_fields_v2: {},
        client: null,
        work_request: null,
        subassets: [],
        checklist: null,
        custom_fields: [],
        description: "",
        asset: null,
        maintenance_type: null,
        maintenance_categories: [],
        location: null,
        planned_start: moment(clickedDate),
        time_needed: "",
        deadline: null,
        executors: null,
        executor_type: "employee",
        status: null,
      });
  }

  // Events data model
  const events: EventInput[] = filter(calendarMaintenances, filters, initialFilters).map(
    (item: IMaintenance) => ({
      id: item.id.toString(),
      title: `${t(TRANSLATION_KEY.maintenance)} ${item.order_number}`,
      start: parseDate(item.planned_start),
      borderColor: item.maintenance_type ? item.maintenance_type.color : undefined,
      backgroundColor: item.maintenance_type ? item.maintenance_type.color : undefined,
      extendedProps: {
        asset_id: item.asset && item.asset.id,
        maintenance: item,
      },
    }),
  );

  // Event callbacks
  function onEventDrop(eventInfo: EventDropArg) {
    let droppedMaintenance: IMaintenance = calendarMaintenances.find(
      (item: IMaintenance) => item.id === Number(eventInfo.event.id),
    )!;
    updateMaintenanceXHR(
      {
        body: { planned_start: eventInfo.event.startStr },
        id: droppedMaintenance.id,
        errorCallback: (err) => {
          if (err.response.data?.message?.custom_field) {
            message.error(
              t(err.response.data.message.message || "").replace(
                "$_dynamic_column",
                err.response.data.message.custom_field,
              ),
            );
            return;
          }
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: (data) => {
          if (data.results) {
            // Update calendar maintenances
            let newCalendarMaintenances = [...calendarMaintenances];
            let index = newCalendarMaintenances.findIndex(
              (item) => item.id === droppedMaintenance.id,
            );
            newCalendarMaintenances.splice(index, 1, data.results);
            dispatch(
              maintenanceSlice.actions.getCalendarMaintenancesSuccess({
                message: "",
                results: newCalendarMaintenances,
              }),
            );
            // Update live maintenances
            let maintenances = [...liveMaintenances];
            let _index = maintenances.findIndex((m) => m.id === data.results?.id);
            maintenances.splice(_index, 1, data.results);
            dispatch(
              maintenanceSlice.actions.getLiveMaintenancesSuccess({
                message: "",
                results: { data: maintenances, cursor: null },
              }),
            );
            dispatch(dashboardSlice.actions.set_categoriesTypes(maintenances));
          }
        },
      },
      dispatch,
    );
  }

  function onEventResize(eventInfo: EventResizeDoneArg) {
    // message.info(`${eventInfo.event.title} was resized to ${eventInfo.event.endStr}`)
  }

  function onDrop(eventInfo: DropArg) {
    let droppedMaintenance: IMaintenance = dropEvents.find(
      (item: IMaintenance) => item.id === Number(eventInfo.draggedEl.id),
    )!;
    updateMaintenanceXHR(
      {
        body: { planned_start: moment(eventInfo.dateStr).toISOString(true) },
        id: droppedMaintenance.id,
        errorCallback: (err) => {
          if (err.response.data?.message?.custom_field) {
            message.error(
              t(err.response.data.message.message || "").replace(
                "$_dynamic_column",
                err.response.data.message.custom_field,
              ),
            );
            return;
          }
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: (data) => {
          if (data.results) {
            // Update droppable maintenances
            let index = droppableCalendarMaintenances.findIndex(
              (item: IMaintenance) => item.id === droppedMaintenance.id,
            );
            let newDroppableCalendarMaintenances = [...droppableCalendarMaintenances];
            newDroppableCalendarMaintenances.splice(index, 1);
            set_dropEvents(newDroppableCalendarMaintenances);
            dispatch(
              maintenanceSlice.actions.getDroppableCalendarMaintenancesSuccess({
                message: "",
                results: newDroppableCalendarMaintenances,
              }),
            );
            // Update calendar maintenances
            let newCalendarMaintenances = [...calendarMaintenances];
            newCalendarMaintenances.push({
              ...droppedMaintenance,
              planned_start: eventInfo.dateStr,
            });
            dispatch(
              maintenanceSlice.actions.getCalendarMaintenancesSuccess({
                message: "",
                results: newCalendarMaintenances,
              }),
            );
            // Update live maintenances
            let maintenances = [...liveMaintenances];
            let _index = maintenances.findIndex((m) => m.id === data.results?.id);
            maintenances.splice(_index, 1, data.results);
            dispatch(
              maintenanceSlice.actions.getLiveMaintenancesSuccess({
                message: "",
                results: { data: maintenances, cursor: null },
              }),
            );
            dispatch(dashboardSlice.actions.set_categoriesTypes(maintenances));
          }
        },
      },
      dispatch,
    );
  }

  function onEventClick(eventInfo: EventClickArg) {
    // message.info(`${eventInfo.event.title} was clicked`)
    // eventInfo.event.remove()
  }

  function capitalize(text: string): string {
    if (!text || text == null) {
      return "";
    }
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
  }

  // Options
  const headerToolbar = {
    left: `prev,next today dayGridMonth,timeGridWeek${
      hasPermission(user.account.permissions, ["manage_wo"]) ? " addMaintenances" : ""
    }`,
    center: "",
    right: "title",
  };

  const localeMap: { [language in Languages]: LocaleInput } = {
    bs: hr,
    de,
    en,
    hr,
    rs,
  };

  if (initialLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Spin spinning={initialLoading} />
      </div>
    );
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <div
        style={{
          position: "relative",
          height: "100%",
          minHeight: fromModal ? "80vh" : "unset",
          maxHeight: fromModal ? "80vh" : "unset",
        }}
      >
        {/* Filters */}
        <Filters
          filters={filters}
          set_filters={set_filters}
          executor_accounts={executor_accounts}
        />

        {/* Calendar */}
        <Spin
          spinning={
            getCalendarMaintenancesStatus === "loading" ||
            getDroppableCalendarMaintenancesStatus === "loading" ||
            getMaintenanceListStatus === "loading" ||
            updateMaintenanceStatus === "loading" ||
            loading
          }
        >
          <motion.div
            initial={{ width: "100%" }}
            animate={dropEventsVisible ? { width: "calc(100% - 380px)" } : { width: "100%" }}
            transition={{ ease: "anticipate", duration: 0.3 }}
          >
            {/* Calendar */}
            <FullCalendar
              ref={calendarRef}
              loading={(isLoading) => onCalendarLoad(isLoading)}
              locale={localeMap[user.account.language as keyof typeof localeMap]}
              aspectRatio={aspectRatio}
              dayMaxEvents={5}
              selectable={hasPermission(user.account.permissions, ["manage_wo"])}
              selectMirror={true}
              moreLinkClick="popover"
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              datesSet={handleMonthChange}
              select={toggleDrawer}
              eventContent={RenderEvent}
              events={events}
              editable
              eventDrop={onEventDrop}
              eventResize={onEventResize}
              // droppable
              drop={onDrop}
              eventClick={onEventClick}
              headerToolbar={headerToolbar}
              buttonText={{
                today: capitalize(
                  calendarRef.current?.getApi()?.currentDataManager?.getCurrentData()
                    ?.localeDefaults?.buttonText?.today || "Today",
                ),
                month: capitalize(
                  calendarRef.current?.getApi()?.currentDataManager?.getCurrentData()
                    ?.localeDefaults?.buttonText?.month || "Month",
                ),
                week: capitalize(
                  calendarRef.current?.getApi()?.currentDataManager?.getCurrentData()
                    ?.localeDefaults?.buttonText?.week || "Week",
                ),
              }}
              customButtons={{
                addMaintenances: {
                  text: t(TRANSLATION_KEY.maintenances),
                  click: () => set_dropEventsVisible((previousState) => !previousState),
                },
              }}
            />
          </motion.div>

          {/* Drawer */}
          <Drawer
            title={t(TRANSLATION_KEY.addOrder)}
            placement="right"
            closable={false}
            destroyOnClose={true}
            visible={!!addMaintenance}
            onClose={() => set_addMaintenance(undefined)}
            getContainer={false}
            width={520}
          >
            {/* Drawer component => New maintenance */}
            <MaintenanceForm
              maintenance={addMaintenance}
              onClose={() => set_addMaintenance(undefined)}
              hh={timeNeeded.hh}
              mm={timeNeeded.mm}
            />
          </Drawer>
        </Spin>
      </div>

      <Drawer
        title={t(TRANSLATION_KEY.scheduleMaintenance)}
        placement="right"
        onClose={() => set_dropEventsVisible(false)}
        visible={dropEventsVisible}
        width={380}
        style={{ position: "absolute", right: -20, height: "100%" }}
        bodyStyle={{ padding: 0 }}
        getContainer={false}
        mask={false}
        closable
      >
        <DropEvents dropEvents={dropEvents} set_dropEventsVisible={set_dropEventsVisible} />
      </Drawer>
    </div>
  );
};

export default CalendarMaintenances;
