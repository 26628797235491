import { userSlice } from ".";
import { AppDispatch, store } from "../..";
import { IApiResponse, IDataToApi } from "../../../models";
import { IResetPassword } from "../../../pages/auth/Login";
import { ISetNewPassword } from "../../../pages/auth/SetNewPassword";
import {
  CreateViewXHRResponseType,
  IFormAccount,
  IRegistration,
  IUser,
  IUserAccount,
  IUserNotification,
  MqttCreds,
  TableView,
  TableViewCategories,
} from "../../../models/user";
import { connectToMqtt, G_MQTT_CLIENT } from "../../../mqtt";
import api, { failedQueue, isRefreshing } from "../../../services";
import { accountsSlice } from "../accounts";
import { assetSlice } from "../asstes";
import { dashboardSlice } from "../dashboard";
import { maintenanceSlice } from "../maintenance";
import { companySlice } from "../partner";
import { settingsSlice } from "../settings";
import { supplierSlice } from "../supplier";
import { warehouseSlice } from "../warehouse";
import { TOPICS } from "../../../helpers/functions";
import axios from "axios";

export const loginXHR = async (
  data: Partial<IDataToApi<{ email: string; password: string }, IApiResponse<IUser>>>,
  dispatch: AppDispatch,
) => {
  try {
    dispatch(userSlice.actions.login());

    let response = await axios.post<IApiResponse<IUser>>(
      `${process.env.REACT_APP_HOST_BACKEND}/api/auth/login/`,
      data.body,
    );

    if (response.data.results) {
      //redoslijed mora ovoakav biti successCallback ce potrazit token iz storage stoga prvo postaviti pa onda callback
      //zadnji dispatch succes kada se sve odradi tek onda smatramo uspjesnim
      await localStorage.setItem("refreshToken", response.data.results.token.refresh);
      await localStorage.setItem("token", response.data.results.token.access);
      await localStorage.setItem("user", JSON.stringify(response.data));

      if (data.successCallback) {
        data.successCallback(response.data);
      }

      dispatch(userSlice.actions.loginSucceess(response.data));
    }
  } catch (error: any) {
    //
    if (data.errorCallback) {
      data.errorCallback(error?.response?.data);
    }

    dispatch(userSlice.actions.loginFail(error.message));
  }
};

export const registrationXHR = async (
  data: Partial<IDataToApi<IRegistration, IApiResponse<IUser>>>,
  dispatch: AppDispatch,
) => {
  try {
    dispatch(userSlice.actions.registration());

    let response = await api.post<IApiResponse<IUser>>(`companies/registration/`, data.body);
    if (data.successCallback) {
      data.successCallback(response.data);
    }

    dispatch(userSlice.actions.registrationSuccess(response.data));
  } catch (error: any) {
    //
    if (data.errorCallback) {
      data.errorCallback(error?.response?.data);
    }

    dispatch(userSlice.actions.registrationFail(error.message));
  }
};

export const resetPasswordEmailXHR = async (
  data: Partial<IDataToApi<IResetPassword, IApiResponse<IResetPassword>>>,
  dispatch: AppDispatch,
) => {
  try {
    dispatch(userSlice.actions.resetPasswordEmail());

    let response = await api.post<IApiResponse<IResetPassword>>(
      `accounts/reset_password_email/`,
      data.body,
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }

    dispatch(userSlice.actions.resetPasswordEmailSuccess(response.data));
  } catch (error: any) {
    //
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => resetPasswordEmailXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error?.response?.data);
    }

    dispatch(userSlice.actions.resetPasswordEmailFail(error.message));
  }
};

export const setNewPasswordXHR = async (
  data: Partial<IDataToApi<ISetNewPassword, IApiResponse<ISetNewPassword>>>,
  dispatch: AppDispatch,
) => {
  try {
    dispatch(userSlice.actions.setNewPassword());
    let response = await api.post<IApiResponse<ISetNewPassword>>(
      `accounts/set_new_password/`,
      data.body,
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(userSlice.actions.setNewPasswordSuccess(response.data));
  } catch (error: any) {
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => setNewPasswordXHR(data, dispatch));
      }
      return;
    }
    //
    if (data.errorCallback) {
      data.errorCallback(error?.response?.data);
    }
    dispatch(userSlice.actions.setNewPasswordFail(error.message));
  }
};

export const changePasswordXHR = async (data: any, dispatch: AppDispatch) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(userSlice.actions.changePassword());
    let response = await api.put<IApiResponse<string>>(
      `accounts/accounts/${data.id}/change_password/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(userSlice.actions.changePasswordSuccess(response.data));
  } catch (error: any) {
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => changePasswordXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(userSlice.actions.changePasswordFail(error.message));
  }
};

export const new_way_logoutXHR = async (data: null, dispatch: AppDispatch) => {
  const token = await localStorage.getItem("token");
  // if(!token){
  //   //logout ide sa reload stranice posto zelim resetirat redux
  //   return;
  // }
  let refresh_token = await localStorage.getItem("refreshToken");
  const { account } = store.getState().userReducer.user;
  let _t = TOPICS(account.topic, account.company.topic);
  Object.keys(_t).forEach((x, i) => {
    G_MQTT_CLIENT?.unsubscribe(_t[x]);
  });

  localStorage.removeItem("refreshToken");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  dispatch(userSlice.actions.logout());
  dispatch(accountsSlice.actions.resetState());
  dispatch(assetSlice.actions.resetState());
  dispatch(dashboardSlice.actions.resetState());
  dispatch(maintenanceSlice.actions.resetState());
  dispatch(companySlice.actions.resetState());
  dispatch(settingsSlice.actions.resetState());
  dispatch(supplierSlice.actions.resetState());
  dispatch(warehouseSlice.actions.resetState());
  dispatch(userSlice.actions.resetState());

  try {
    if (refresh_token) {
      let response = await api.post<IApiResponse<string>>(
        `auth/logout/`,
        {
          refresh_token,
        },
        {
          headers: { Authorization: "Bearer " + token },
        },
      );
    }
  } catch (error: any) {
    console.log(error);
  }
};

export const meXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IUserAccount>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    const st = await localStorage.getItem("user");
    let user: IApiResponse<IUser> | null = null;

    if (st) {
      user = JSON.parse(st);
      console.log(user);
      let _t = TOPICS(
        user?.results?.account.topic || "",
        user?.results?.account.company.topic || "",
      );
      Object.keys(_t).forEach((x, i) => {
        if (G_MQTT_CLIENT) {
          G_MQTT_CLIENT.unsubscribe(_t[x]);
        }
      });
    }

    dispatch(
      userSlice.actions.me({
        loading: data.loading || "loading",
      }),
    );
    if (st) {
      user = JSON.parse(st);
      let _t = TOPICS(
        user?.results?.account.topic || "",
        user?.results?.account.company.topic || "",
      );
      Object.keys(_t).forEach((x, i) => {
        if (G_MQTT_CLIENT) {
          G_MQTT_CLIENT.unsubscribe(_t[x]);
        }
      });
    }
    let response = await api.get<IApiResponse<IUserAccount>>(`/accounts/me/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    const str = localStorage.getItem("user");

    let tmp: IApiResponse<IUser> = JSON.parse(str || "");
    if (tmp.results) {
      tmp.results.account = response.data.results!;
    }
    dispatch(userSlice.actions.meSuccess(tmp));
  } catch (error: any) {
    dispatch(userSlice.actions.meFail(error.message));
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => meXHR(data, dispatch));
      }
      if (data.errorCallback) {
        data.errorCallback(error);
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(userSlice.actions.meFail(error.message));
  }
};

export const confirmAndSetPasswordXHR = async (
  data: Partial<
    IDataToApi<{ reg_token: string; password: string; isMobile: boolean }, IApiResponse<IUser>>
  >,
  dispatch: AppDispatch,
) => {
  try {
    dispatch(userSlice.actions.confirmAndSetPassword());
    let { isMobile, ...body } = data.body!;
    let response = await api.post<IApiResponse<IUser>>(`/accounts/confirm_and_set_password/`, body);

    if (!isMobile) {
      localStorage.setItem("refreshToken", response.data.results?.token.refresh!);
      localStorage.setItem("token", response.data.results?.token.access!);
      localStorage.setItem("user", JSON.stringify(response.data));
      dispatch(userSlice.actions.confirmAndSetPasswordSuccess(response.data));
    }

    if (data.successCallback) {
      data.successCallback(response.data);
    }
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => confirmAndSetPasswordXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.confirmAndSetPasswordFail(error.message));
  }
};

export const setMqttClient = async (
  data: Partial<IDataToApi<IUserAccount, IApiResponse<unknown>>>,
  dispatch: AppDispatch,
) => {
  //u ovoj funkciji dobivamo model prijavljenog korisnika, pritom cemo jos pozvat backend da nam vrati creds za prijavu na broker
  try {
    if (data.body) {
      let token = localStorage.getItem("token");
      let response = await api.get<IApiResponse<MqttCreds>>(`accounts/get_mqtt_credentials/`, {
        headers: { Authorization: "Bearer " + token },
      });
      if (response.data.results) {
        connectToMqtt(data.body, response.data.results);
      }
    }
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => setMqttClient(data, dispatch));
      }
      return;
    }

    if (data.errorCallback) {
      data.errorCallback(error);
    }
  }
};

export const updateUserXHR = async (
  data: Partial<IDataToApi<Partial<Omit<IFormAccount, "assets">>, IApiResponse<IUserAccount>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(userSlice.actions.updateUser());
    let response = await api.put<IApiResponse<IUserAccount>>(
      `accounts/accounts/${data.id}/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(userSlice.actions.updateUserSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateUserXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.updateUserFail(error.message));
  }
};

export const getNotificationsAllXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IUserNotification[]>> & { mergeData: boolean }>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(userSlice.actions.getNotifications());
    let response = await api.get<IApiResponse<IUserNotification[]>>(
      `notifications/notifications/list_all/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(
      userSlice.actions.getNotificationsSuccess({
        results: response.data.results,
        mergeData: data.mergeData || false,
        message: response.data.message,
      }),
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getNotificationsAllXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.getNotificationsFail(error.message));
  }
};

export const readNotificationXHR = async (
  data: Partial<IDataToApi<{ seen: boolean; notifications: number[] }, IApiResponse<string>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(userSlice.actions.readNotification());
    let response = await api.post<IApiResponse<string>>(`notifications/seen/`, data.body, {
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(userSlice.actions.readNotificationSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => readNotificationXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.readNotificationFail(error.message));
  }
};

export const addCustomNotificationXHR = async (
  data: Partial<IDataToApi<{ text: string; roles: number[] }, IApiResponse<string>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(userSlice.actions.addCustomNotification());
    let response = await api.post<IApiResponse<string>>(
      `notifications/custom_notification/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(userSlice.actions.addCustomNotificationSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addCustomNotificationXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.addCustomNotificationFail(error.message));
  }
};

export const readAllNotificationsXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<IUserNotification[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    let response = await api.post<IApiResponse<IUserNotification[]>>(
      `notifications/seen/all/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(
      userSlice.actions.unreadedNotificationsCountSuccess({
        results: {
          count: 0,
        },
        message: "",
      }),
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => readAllNotificationsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
  }
};

export const unreadedNotificationsCountXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<{ count: number }>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(userSlice.actions.unreadedNotificationsCount());
    let response = await api.get<IApiResponse<{ count: number }>>(
      `notifications/notifications/count/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(userSlice.actions.unreadedNotificationsCountSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => unreadedNotificationsCountXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.unreadedNotificationsCountFail(error.message));
  }
};

export const createViewXHR = async (
  data: Partial<
    IDataToApi<
      {
        category: TableViewCategories;
        name: string;
      },
      IApiResponse<CreateViewXHRResponseType>
    >
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(userSlice.actions.createView());
    let response = await api.post<IApiResponse<CreateViewXHRResponseType>>(
      `settings/view/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    if (!data.body) {
      console.log("data.body is undefined");
      alert("data.body is undefined");
      return;
    }
    dispatch(
      userSlice.actions.createViewSuccess({ ...response.data, category: data.body.category }),
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => createViewXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.createViewFail(error.message));
  }
};

export const updateViewXHR = async (
  data: Partial<
    IDataToApi<TableView, IApiResponse<CreateViewXHRResponseType>> & {
      viewToUpdate: TableViewCategories;
    }
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(userSlice.actions.updateView());
    let response = await api.put<IApiResponse<CreateViewXHRResponseType>>(
      `settings/view/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    if (!data.viewToUpdate) {
      console.log("viewToUpdate is undefined");
      alert("viewToUpdate is undefined");
      return;
    }
    dispatch(
      userSlice.actions.updateViewSuccess({ ...response.data, viewsToUpdate: data.viewToUpdate }),
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateViewXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.updateViewFail(error.message));
  }
};

// -----
